import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

const DurationDropdown = ({ disabled, onSelection, selectedDuration }) => {
  const scope = 'shared.measures_editor.measure.edit_modal.chart_options.reporting_period_selection';

  const durationDropdownProps = {
    id: 'reporting-period-duration',
    options: _.map(_.range(1, 31, 1), (value) => ({
      title: value.toString(),
      value: value
    })),
    placeholder: I18n.t('choose_day', { scope }),
    value: selectedDuration,
    onSelection,
    showOptionsBelowHandle: true,
    disabled
  };

  return <Dropdown {...durationDropdownProps} />;
};

DurationDropdown.propTypes = {
  disabled: PropTypes.bool,
  onSelection: PropTypes.func.isRequired,
  selectedDuration: PropTypes.number
};

export default DurationDropdown;
