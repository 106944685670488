import _ from 'lodash';

/**
 * @param {Array[Object]} targets targets saved on a measure
 * @return {boolean} whether targets are the default targets
 */
export default (targets) => {
  if (!_.isArray(targets)) {
    return false;
  }

  return targets.some((target) => !_.isEmpty(target.value) || !_.isEmpty(target.label));
};
