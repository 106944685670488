import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'common/i18n';
import { getAllQuarterStartMonths } from 'common/dates';
import Dropdown from 'common/components/Dropdown';

const translationScope = 'shared.measures_editor.measure.edit_modal.chart_options';

export function generateQuarterDropdownOptions(firstQuarterStartMonth) {
  return _.map(
    getAllQuarterStartMonths(firstQuarterStartMonth),
    (quarterStartMonth, index) => {
      const quarterStart = moment().month(quarterStartMonth);
      const quarterEnd = quarterStart.clone().add(2, 'months');
      const monthRange = `(${quarterStart.format('MMM')} - ${quarterEnd.format('MMM')})`;
      const translationOptions = { scope: translationScope, quarterNumber: (index + 1) };
      const quarterString = I18n.t('quarter', translationOptions);

      return {
        title: `${quarterString} ${monthRange}`,
        value: quarterStartMonth
      };
    });
}

export default function QuarterDropdown(props) {
  const { firstQuarterStartMonth } = props;

  const dropdownProps = {
    id: props.id,
    value: props.value,
    onSelection: props.onSelection,
    options: generateQuarterDropdownOptions(firstQuarterStartMonth),
    placeholder: I18n.t('quarter_dropdown_placeholder', { scope: translationScope }),
    showOptionsBelowHandle: props.showOptionsBelowHandle,
    disabled: props.disabled,
    className: 'quarter-dropdown'
  };

  return <Dropdown {...dropdownProps} />;
}

QuarterDropdown.propTypes = {
  firstQuarterStartMonth: PropTypes.number,
  id: PropTypes.string,
  value: PropTypes.number,
  onSelection: PropTypes.func,
  showOptionsBelowHandle: PropTypes.bool,
  disabled: PropTypes.bool
};
