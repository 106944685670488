import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';

import { setStatusLabelOverride } from 'common/measures_editor/actions/editor';

export const StatusLabelInput = ({ onLabelChange, labels, statusValue }) => {
  const label = (labels && labels[statusValue]) || '';
  const labelDefault = I18n.t(`shared.performance_measures.measure.status.values.${statusValue}`);

  return (
    <input
      className="text-input status-label-input"
      id={`status-${statusValue}-label`}
      maxLength="18"
      onChange={(e) => onLabelChange(statusValue, e.target.value)}
      placeholder={labelDefault}
      type="text"
      value={label} />
  );
};

StatusLabelInput.propTypes = {
  labels: PropTypes.objectOf(PropTypes.string),
  onLabelChange: PropTypes.func.isRequired,
  statusValue: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { onLabelChange: setStatusLabelOverride },
  dispatch
);

export default connect(() => ({}), mapDispatchToProps)(StatusLabelInput);
