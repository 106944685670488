import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { PeriodSizes } from 'common/performance_measures/lib/constants';
import reportingPeriodPropType from 'common/performance_measures/propTypes/reportingPeriodPropType';
import DatePicker from 'common/components/DatePicker';

import QuarterDropdown from '../timeSelectors/QuarterDropdown';
import MonthDropdown from '../timeSelectors/MonthDropdown';
import WeekDropdown from '../timeSelectors/WeekDropdown';
import YearDropdown from '../timeSelectors/YearDropdown';
import Warning from '../Warning';

export default class XAxisEndsBeforeDateSelection extends Component {
  constructor(props) {
    super(props);
    if (props.endsBeforeDate && props.reportingPeriod) {
      const endsBeforeDate = moment(props.endsBeforeDate);
      const size = _.get(props, 'reportingPeriod.size');
      const selectedDate = moment(endsBeforeDate).subtract(1, size);

      this.state = {
        endsBeforeDate: props.endsBeforeDate,
        selectedDate: selectedDate.format('YYYY-MM-DD'),
        selectedDay: selectedDate.date(),
        selectedMonth: selectedDate.month(),
        selectedYear: selectedDate.year()
      };
    } else {
      this.state = {};
    }
  }

  onChangeReportingPeriod = ({ day, month, year }) => {
    const { state } = this;
    const nextState = {
      selectedDay: _.isFinite(day) ? day : state.selectedDay,
      selectedMonth: _.isFinite(month) ? month : state.selectedMonth,
      selectedYear: _.isFinite(year) ? year : state.selectedYear
    };

    if (_(nextState).pick(['selectedDay', 'selectedMonth', 'selectedYear']).every(_.isFinite)) {
      const nextSelectedDate = moment({
        date: nextState.selectedDay,
        month: nextState.selectedMonth,
        year: nextState.selectedYear
      });
      nextState.selectedDate = nextSelectedDate.format('YYYY-MM-DD');

      const size = _.get(this.props, 'reportingPeriod.size');
      nextState.endsBeforeDate = nextSelectedDate.add(1, size).format('YYYY-MM-DD');

      this.props.onChangeReportingPeriod(nextState.endsBeforeDate);
    }

    this.setState(nextState);
  };

  renderYearDropdown(onSelection) {
    const { reportingPeriod } = this.props;
    const { selectedYear } = this.state;
    if (!onSelection) {
      onSelection = ({ value }) => this.onChangeReportingPeriod({ day: 1, year: value });
    }

    return (
      <YearDropdown
        disabled={!_.get(reportingPeriod, 'size')}
        selectedYear={selectedYear}
        onSelection={onSelection}
      />
    );
  }

  renderQuarterDropdown() {
    const { firstQuarterStartMonth, reportingPeriod } = this.props;

    const quarterDropdownProps = {
      id: 'reporting-period-selected-quarter',
      firstQuarterStartMonth,
      value: this.state.selectedMonth,
      onSelection: ({ value }) => this.onChangeReportingPeriod({ day: 1, month: value }),
      showOptionsBelowHandle: true,
      disabled: !_.get(reportingPeriod, 'size')
    };

    return <QuarterDropdown {...quarterDropdownProps} />;
  }

  renderMonthDropdown() {
    const { reportingPeriod } = this.props;
    const { selectedMonth } = this.state;
    return (
      <MonthDropdown
        disabled={!_.get(reportingPeriod, 'size')}
        selectedMonth={selectedMonth}
        onSelection={({ value }) => this.onChangeReportingPeriod({ day: 1, month: value })}
      />
    );
  }

  renderWeekDropdown() {
    const { reportingPeriod } = this.props;
    const { selectedYear, selectedDate } = this.state;
    const onSelection = ({ value }) => {
      const date = moment(value);
      this.onChangeReportingPeriod({
        day: date.date(),
        month: date.month(),
        year: date.year()
      });
    };

    return (
      <WeekDropdown
        disabled={!_.get(reportingPeriod, 'size') || !selectedYear}
        selectedDate={selectedDate}
        targetYear={selectedYear}
        onSelection={onSelection}
        reportingPeriod={reportingPeriod}
      />
    );
  }

  renderDayDatePicker() {
    const { selectedDate } = this.state;
    const datePickerProps = {
      date: selectedDate,
      dateFormat: 'MM/dd/yyyy',
      onChangeDate: (date) => {
        const newDate = moment(date);
        this.onChangeReportingPeriod({
          day: newDate.date(),
          month: newDate.month(),
          year: newDate.year()
        });
      }
    };

    return <DatePicker {...datePickerProps} />;
  }

  renderInputs() {
    const reportingPeriodSize = _.get(this.props, 'reportingPeriod.size');

    switch (reportingPeriodSize) {
      case PeriodSizes.DAY:
        return <div>{this.renderDayDatePicker()}</div>;
      case PeriodSizes.QUARTER:
        return (
          <div>
            {this.renderQuarterDropdown()}
            {this.renderYearDropdown()}
          </div>
        );
      case PeriodSizes.WEEK: {
        const onYearSelection = ({ value }) => {
          const { selectedDate } = this.state;
          if (!selectedDate) {
            this.onChangeReportingPeriod({ year: value });
          } else {
            // The selected week will need to be corrected
            // for the new year
            const dayOfWeek = moment(selectedDate).day();
            const date = moment(selectedDate).year(value).day(dayOfWeek);
            this.onChangeReportingPeriod({ year: date.year(), month: date.month(), day: date.date() });
          }
        };
        return (
          <div>
            {this.renderWeekDropdown()}
            {this.renderYearDropdown(onYearSelection)}
          </div>
        );
      }
      case PeriodSizes.YEAR: {
        const onYearSelection = ({ value }) => {
          const startDate = _.get(this.props, 'reportingPeriod.startDateConfig.date');
          const month = moment(startDate).month();
          this.onChangeReportingPeriod({ day: 1, month, year: value });
        };
        return <div>{this.renderYearDropdown(onYearSelection)}</div>;
      }
      case PeriodSizes.MONTH:
      default:
        return (
          <div>
            {this.renderMonthDropdown()}
            {this.renderYearDropdown()}
          </div>
        );
    }
  }

  renderValidationWarning() {
    const { validationWarningText } = this.props;
    return _.isEmpty(validationWarningText) ? null : <Warning text={validationWarningText} />;
  }

  render() {
    const { validationWarningText } = this.props;

    const classes = classNames('reporting-period-selection', {
      'has-validation-warnings': !_.isEmpty(validationWarningText)
    });

    return (
      <div className={classes}>
        {this.renderValidationWarning()}
        {this.renderInputs()}
      </div>
    );
  }
}

XAxisEndsBeforeDateSelection.propTypes = {
  firstQuarterStartMonth: PropTypes.number,
  onChangeReportingPeriod: PropTypes.func.isRequired,
  reportingPeriod: reportingPeriodPropType,
  // `endsBeforeDate` should also be passed in as `key`. This will prevent the
  // component's state from getting out of sync with the editor's state
  // by replacing this component every time the `key` prop changes.
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
  endsBeforeDate: PropTypes.string,
  validationWarningText: PropTypes.string
};
