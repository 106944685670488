import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';
import SocrataIcon from 'common/components/SocrataIcon';
import { getIconForDataType } from 'common/icons';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import Warning from './Warning';
import { setActivePanel } from '../../actions/editor';
import { EditTabs } from '../../lib/constants';
import {
  isColumnUsableWithMeasureArgument,
  measureArgumentNeedsNumericColumn
} from 'common/performance_measures/measureCalculator';

const scope = 'shared.measures_editor.measure.edit_modal.calculation';
// A dropdown list of columns, automatically filtered for relevance to the a given measure
// argument (i.e., only date columns are displayed for Recent Value's dateColumn argument).
export class ColumnDropdown extends Component {
  renderNumericColumnRequiredWarning() {
    const { openDataSourceTab } = this.props;
    const warningOptions = {
      linkAction: openDataSourceTab,
      linkText: I18n.t('select_dataset', { scope }),
      text: I18n.t('numeric_column_needed', { scope })
    };
    return <Warning {...warningOptions} />;
  }

  render() {
    const {
      columnFieldName,
      calculationNotConfigured,
      displayableFilterableColumns,
      id,
      labelledBy,
      measure,
      measureArgument,
      onSelectColumn
    } = this.props;

    const columns = _.filter(displayableFilterableColumns, (column) => {
      return this.props.isColumnUsableWithMeasureArgument(column, measure, measureArgument);
    });

    const dropdownOptions = {
      disabled: calculationNotConfigured || _.isEmpty(columns),
      placeholder: I18n.t('choose_column', { scope }),
      onSelection: (option) => {
        onSelectColumn(option.value);
      },
      options: _.map(columns, (column) => ({
        title: column.name,
        value: column.fieldName,
        icon: <SocrataIcon name={getIconForDataType(column.renderTypeName)} />
      })),
      value: columnFieldName,
      id,
      showOptionsBelowHandle: true,
      labelledBy
    };

    const hasNumericWarning = !calculationNotConfigured &&
      _.isEmpty(columns) &&
      measureArgumentNeedsNumericColumn(measure, measureArgument);

    return (
      <div className="column-dropdown">
        {hasNumericWarning && this.renderNumericColumnRequiredWarning()}
        <Dropdown {...dropdownOptions} />
      </div>
    );
  }
}

ColumnDropdown.defaultProps = {
  calculationNotConfigured: false,
  isColumnUsableWithMeasureArgument // Imported above
};

ColumnDropdown.propTypes = {
  // Just a DOM ID.
  id: PropTypes.string,

  // See:
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute
  labelledBy: PropTypes.string,
  calculationNotConfigured: PropTypes.bool,

  // In order to determine which columns to display,
  // we need to know where we want to put this column
  // in the measure arguments. Some sane values for this
  // include: valueColumn dateColumn numeratorColumn denominatorColumn
  measureArgument: PropTypes.string.isRequired,

  // Currently selected column field name. Note this is a controlled
  // component - it's up to the consumer to provide updated values
  // for this prop.
  columnFieldName: PropTypes.string,

  // The list of columns we will look through. Any columns
  // relevant to the given measure and measureArgument will
  // be presented to the user.
  displayableFilterableColumns: PropTypes.arrayOf(PropTypes.shape({
    renderTypeName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
  })),

  // Measure we're choosing columns for.
  measure: measurePropType.isRequired,

  // Called back with the column fieldName when the user makes a selection.
  onSelectColumn: PropTypes.func.isRequired,

  // The default column suitability check can be overridden here for tests. We expect the following signature:
  // (column, measure, measureArgument) => boolean
  //
  // column, measure, and measureArgument all come from the props (displayableFilterableColumns, measure, and
  // measureArgument, respectively).
  isColumnUsableWithMeasureArgument: PropTypes.func,
  openDataSourceTab: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openDataSourceTab: () => setActivePanel(EditTabs.DATA_SOURCE)
  }, dispatch);
}

export default connect(_.stubObject, mapDispatchToProps)(ColumnDropdown);
