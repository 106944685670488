import PropTypes from 'prop-types';

export const SaveStates = Object.freeze({
  IDLE: 'IDLE',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
  ERRORED: 'ERRORED'
});

export const EditTabs = Object.freeze({
  CALCULATION: 'calculation',
  DATA_SOURCE: 'data-source',
  CHART_OPTIONS: 'chart-options',
  GENERAL_INFO: 'general-info',
  METHODS_AND_ANALYSIS: 'methods-and-analysis',
  REPORTING_PERIOD: 'reporting-period',
  TARGETS: 'targets',
  STATUS: 'status'
});

export const TargetColumnNames = Object.freeze({
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  VALUE: 'value',
  LABEL: 'label',
  QUARTER: 'quarter',
  DAY: 'day'
});

// This is uesd as a sentinel value for data that is currently being
// fetched. This pattern is discouraged as it tends to generate confusing
// code - instead, prefer a separate property to indicate status (i.e.,
// have separate `view`, `viewIsLoading`, and `viewLoadingError` properties).
// The sentinel pattern is especially weak when additional status must be
// provided, for instance load progress information or error details.
// It's also hard to write sane type definitions in i.e. propTypes.
export const LOADING_SENTINEL = 'LOADING_SENTINEL';
// PropType helper for accepting either the LOADING_SENTINEL or some
// other type.
export const propTypeOrLoadingSentinel = (propType) => PropTypes.oneOfType([PropTypes.string, propType]);

export const NUMERATOR = 'numerator';
export const DENOMINATOR = 'denominator';
