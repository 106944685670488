import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import airbrake from 'common/airbrake';
import I18n from 'common/i18n';

import { isDevelopmentEnvironment } from 'common/environment';
import store from './store';
import App from './App';

airbrake.init(window.serverConfig.airbrakeProjectId, window.serverConfig.airbrakeKey);

// Defer rendering so the spinner in the ERB can render.
_.defer(function() {
  // Render the App, falling back to rendering an error if it fails.
  try {
    ReactDOM.render(
      <App store={store} />,
      document.querySelector('#app')
    );
  } catch (e) {
    // TODO: Catching-all makes stack traces hard to parse in a development context. The links
    // in the console don't take you to the code that threw. Instead, they take you to the
    // raw webpack-generated eval() calls.
    console.error('Fatal error when rendering:', e);

    ReactDOM.render(
      <div>
        <div className="alert error alert-full-width-top">{I18n.t('shared.measures_editor.render_error')}</div>
        {isDevelopmentEnvironment() && (<pre>{e.stack}</pre>)}
      </div>,
      document.querySelector('.placeholder-wrapper')
    );

    throw e;
  }

  // Show the footer once the App has attempted to render.
  const footer = document.querySelector('#site-chrome-footer');
  if (footer) {
    footer.style.visibility = 'visible';
  }
});
