import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import I18n from 'common/i18n';
import { formatDateWithLocale } from 'common/dates';
import { PeriodTypes } from 'common/performance_measures/lib/constants';
import {
  hasMeasureEnded
} from 'common/performance_measures/lib/measureHelpers';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

// Pane containing high-level (mostly prose) description of the measure.
export class AboutThisMeasure extends Component {
  render() {
    const calculationType = _.get(this.props, 'measure.metricConfig.type', '');
    const endsBeforeDate = _.get(this.props, 'measure.metricConfig.reportingPeriod.endsBeforeDate');
    const measure = _.get(this.props, 'measure');
    const {
      size: reportingPeriodSize,
      type: reportingPeriodType
    } = _.get(this.props, 'measure.metricConfig.reportingPeriod', {});
    const isCumulativeMath = _.get(
      this.props, 'measure.metricConfig.arguments.isCumulativeMath',
      false
    );


    // We only want to display this component when it will contain some info.
    if (!calculationType && !(reportingPeriodSize && reportingPeriodType)) {
      return null;
    }

    let reportingPeriodText = '—';
    if (reportingPeriodType && reportingPeriodSize) {
      if (reportingPeriodType === PeriodTypes.OPEN) {
        reportingPeriodText = I18n.t(
          `shared.measures_editor.measure.edit_modal.reporting_period.size.${reportingPeriodSize}_to_date`
        );
      } else {
        reportingPeriodText = I18n.t(
          `shared.measures_editor.measure.edit_modal.reporting_period.size.${reportingPeriodSize}`
        );
      }
    }

    let calculationTypeText = '—';
    if (calculationType) {
      calculationTypeText = I18n.t(
        `shared.measures_editor.calculation_types.${calculationType}`
      );
    }
    if (isCumulativeMath) {
      calculationTypeText += ` (${I18n.t('shared.measures_editor.cumulative')})`;
    }
    const measureEndDate = formatDateWithLocale(moment(endsBeforeDate).subtract(1, 'day'));
    const measureEndDateSection = (
      <div className="metadata-pair">
        <dt className="metadata-pair-key">
          {hasMeasureEnded(measure) ?
            I18n.t('shared.measures_editor.measure.measure_end') :
            I18n.t('shared.measures_editor.measure.measure_ending')}
        </dt>
        <dd className="metadata-pair-value end-date-text">
          {measureEndDate}
        </dd>
      </div>
    );

    return (
      <div className="about-measure">
        <h3 className="about-measure-title">
          {I18n.t('shared.measures_editor.measure.about_this_measure')}
        </h3>
        <div className="metadata-table-wrapper">
          <div className="metadata-section">
            <dl className="metadata-column">
              {endsBeforeDate && measureEndDateSection}
              <div className="metadata-pair">
                <dt className="metadata-pair-key">
                  {I18n.t('shared.measures_editor.measure.reporting_period')}
                </dt>
                <dd className="metadata-pair-value reporting-period-text">
                  {reportingPeriodText}
                </dd>
              </div>
              <div className="metadata-pair">
                <dt className="metadata-pair-key">
                  {I18n.t('shared.measures_editor.measure.calculation_type')}
                </dt>
                <dd className="metadata-pair-value calculation-type-text">
                  {calculationTypeText}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}

AboutThisMeasure.propTypes = {
  measure: measurePropType.isRequired
};

function mapStateToProps(state) {
  return state.view;
}

export default connect(mapStateToProps)(AboutThisMeasure);
