import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

const MonthDropdown = ({ disabled, selectedMonth, onSelection }) => {
  const scope = 'shared.measures_editor.measure.edit_modal.chart_options.reporting_period_selection';
  const monthDropDownProps = {
    id: 'reporting-period-selected-month',
    options: _.map(moment.months(), (month, index) => ({
      title: month,
      value: index
    })),
    placeholder: I18n.t('choose_month', { scope }),
    value: selectedMonth,
    onSelection,
    showOptionsBelowHandle: true,
    disabled
  };

  return <Dropdown {...monthDropDownProps} />;
};

MonthDropdown.propTypes = {
  disabled: PropTypes.bool,
  selectedMonth: PropTypes.number,
  onSelection: PropTypes.func.isRequired
};

MonthDropdown.defaultProps = {
  disabled: false
};

export default MonthDropdown;
