import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import FilterPropType from 'common/propTypes/FilterPropType';
import { isColumnUsableWithMeasureArgument } from 'common/performance_measures/measureCalculator';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import ColumnDropdown from '../ColumnDropdown';
import { setValueColumn } from '../../../actions/editor';
import FilterBar from '../FilterBar';

export class RecentValue extends Component {

  renderFilterBar() {
    const {
      calculationFilters,
      calculationNotConfigured,
      displayableFilterableColumns,
      measure
    } = this.props;

    // displayableFilterableColumns is not available until a data source has been selected _and_ its metadata
    // has loaded, but it is required to load the FilterBar.
    if (_.isEmpty(displayableFilterableColumns)) {
      return;
    }

    const filterableColumns = _.filter(displayableFilterableColumns, (column) => {
      // TODO: make valueColumn a constant
      return isColumnUsableWithMeasureArgument(column, measure, 'valueColumn');
    });

    const filterBarProps = {
      addFilterDisabled: calculationNotConfigured || _.isEmpty(filterableColumns),
      filters: calculationFilters
    };

    return (
      <FilterBar {...filterBarProps} />
    );
  }

  // Left-hand pane with count-specific options.
  renderConfigPane() {
    const {
      calculationNotConfigured,
      displayableFilterableColumns,
      measure,
      onSelectValueColumn,
      valueColumnFieldName
    } = this.props;

    const valueColDropdownOptions = {
      columnFieldName: valueColumnFieldName,
      calculationNotConfigured,
      displayableFilterableColumns,
      labelledBy: 'value-column',
      measure,
      measureArgument: 'valueColumn',
      onSelectColumn: onSelectValueColumn
    };

    return (
      <div className="metric-config">
        <h5>
          {I18n.t('shared.measures_editor.measure.edit_modal.calculation.types.recent.title')}
        </h5>
        <div className="column-dropdown">
          <label className="block-label" id={valueColDropdownOptions.labelledBy}>
            {I18n.t('shared.measures_editor.measure.edit_modal.calculation.types.recent.value_column')}
          </label>
          <ColumnDropdown {...valueColDropdownOptions} />
        </div>
        {this.renderFilterBar()}
      </div>
    );
  }

  renderDefinitionText() {
    return (
      <div className="metric-definition-text">
        <h5>
          {I18n.t('shared.measures_editor.measure.edit_modal.calculation.types.recent.help_title')}
        </h5>
        {I18n.t('shared.measures_editor.measure.edit_modal.calculation.types.recent.help_body')}
      </div>
    );
  }

  render() {
    return (<div className="metric-container">
      {this.renderConfigPane()}
      {this.renderDefinitionText()}
    </div>);
  }
}

RecentValue.defaultProps = {
  calculationNotConfigured: false
};

RecentValue.propTypes = {
  calculationFilters: PropTypes.arrayOf(FilterPropType),
  calculationNotConfigured: PropTypes.bool,
  displayableFilterableColumns: PropTypes.arrayOf(PropTypes.shape({
    renderTypeName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
  })),
  measure: measurePropType.isRequired,
  onSelectValueColumn: PropTypes.func.isRequired,
  valueColumnFieldName: PropTypes.string
};

function mapStateToProps(state) {
  const calculationFilters = _.get(state, 'editor.measure.metricConfig.arguments.calculationFilters');
  const dateColumnFieldName = _.get(state, 'editor.measure.metricConfig.arguments.dateColumn');
  const displayableFilterableColumns = _.get(state, 'editor.displayableFilterableColumns');
  const valueColumnFieldName = _.get(state, 'editor.measure.metricConfig.arguments.valueColumn');

  return {
    calculationFilters,
    dateColumnFieldName,
    displayableFilterableColumns,
    measure: state.editor.measure,
    valueColumnFieldName
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSelectValueColumn: setValueColumn
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentValue);
