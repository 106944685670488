import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

function t(subkey) {
  return I18n.t(`shared.measures_editor.measure.edit_modal.reporting_period.${subkey}`);
}

export class QuarterStartMonthOverride extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  generateQuarterString(quarterNumber = 1) {
    const translationScope = 'shared.measures_editor.measure.edit_modal.chart_options';
    const translationOptions = { scope: translationScope, quarterNumber };
    return I18n.t('quarter', translationOptions);
  }

  render() {
    const {
      firstQuarterStartMonth,
      onQuarterStartMonthSelected
    } = this.props;
    const domainQuarterStartMonth = _.get(window, 'serverConfig.firstQuarterStartMonth', 0);
    const domainStartMonthString = moment().month(domainQuarterStartMonth).date(1).format('MMMM D');

    const monthDropDownProps = {
      id: 'displayed-x-axis-start-reporting-period',
      options: _.map(moment.months(), (month, i) => ({
        title: month,
        value: i
      })),
      value: firstQuarterStartMonth,
      showOptionsBelowHandle: true,
      onSelection: onQuarterStartMonthSelected
    };

    const quartersTable = (startMonth) => {
      const startMonthDate = moment({ month: startMonth, date: 1 });
      return [0, 1, 2, 3].map(quarter => (
        <tr key={quarter}>
          <td className="bold">{this.generateQuarterString(quarter + 1)}</td>
          <td>{moment(startMonthDate).add(quarter * 3, 'month').format('MMMM D')}</td>
          <td>{moment(startMonthDate).add(quarter * 3 + 3, 'month').subtract(1, 'day').format('MMMM D')}</td>
        </tr>
      ));
    };

    const domainQuarterStartMonthNarrative =
      I18n.t('shared.measures_editor.measure.edit_modal.reporting_period.domain_quarter_start_month',
      { month: domainStartMonthString });

    return (
      <div>
        <p dangerouslySetInnerHTML={{ __html: domainQuarterStartMonthNarrative }}></p>
        <h6>{t('domain_quarter_start_month_override')}:</h6>
        <Dropdown {...monthDropDownProps} />
        <table className="quarters-table">
          <tbody>
            <tr>
              <td></td>
              <th className="quarters-table-header">Starts</th>
              <th className="quarters-table-header">Ends</th>
            </tr>
            {quartersTable(firstQuarterStartMonth)}
          </tbody>
        </table>
      </div>
    );
  }
}

QuarterStartMonthOverride.propTypes = {
  firstQuarterStartMonth: PropTypes.number,
  onQuarterStartMonthSelected: PropTypes.func.isRequired
};

export default QuarterStartMonthOverride;
