import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import I18n from 'common/i18n';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import { EditTabs, propTypeOrLoadingSentinel } from '../../lib/constants';
import validateConfiguration from '../../lib/validateConfiguration';
import { cancelEditModal, clearState, saveMeasure, setActivePanel } from '../../actions/editor';
import EditModalTab from './EditModalTab';
import EditModalPanel from './EditModalPanel';
import GeneralPanel from './GeneralPanel';
import DataPanel from './DataPanel';
import MethodsPanel from './MethodsPanel';
import CalculationPanel from './CalculationPanel';
import ReportingPeriodPanel from './ReportingPeriodPanel';
import StatusPanel from './StatusPanel';
import TargetsPanel from './TargetsPanel';
import ConfigurationNotice from './ConfigurationNotice';
import ChartOptionsPanel from './ChartOptionsPanel';

import {
  isEndDateValid
} from 'common/performance_measures/lib/measureHelpers';
import {
  requireApprovalRequestWithdrawal
} from 'common/components/AssetActionBar/components/publication_action/utils';
import { fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';

const scope = 'shared.measures_editor.measure.edit_modal';

// Modal for editing several aspects of the measure, grouped into tabs/panels.
export class EditModal extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'renderTabList',
      'renderPanels'
    ]);
  }

  state = {
    guidance: {}
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.publishedCoreView)) {
      // not using assetIdFor because this is always a published or working copy, never revision or story draft
      fetchApprovalsGuidanceV2(this.props.publishedCoreView.id).then(guidance => {
        this.setState({ guidance });
      });
    }
  }

  onCancel = () => {
    const { isInsitu, onClearState } = this.props;
    const confirmationMessage = I18n.t('shared.measures_editor.save_prompt');

    // Don't close the whole edit modal when using Escape key to exit
    // ConfigurationNotice flannel. I wasn't able to use ReactDOM.findDOMNode in
    // a way that didn't break tests, but maybe someone can replace this jQuery
    // usage at some point.
    if ($('#configuration-notice-flannel').is(':visible')) {
      // TODO: This actually needs to check the event, but the Modal's onDismiss
      // doesn't actually pass the event through, so this needs some follow-up.
      // (If we don't check the event, then the user can't click Cancel if the
      // ConfigurationNotice is open.)
      return;
    }

    if (this.isUnmodified() || window.confirm(confirmationMessage)) {
      this.props.onCancel();
      if (isInsitu) {
        onClearState();
      }
    }
  };

  onComplete = () => {
    const { measure, onClearState, onComplete } = this.props;

    if (_.isEmpty(measure.metadata.shortName)) {
      return;
    }
    onComplete(measure);
    onClearState();
  };

  isUnmodified() {
    const { coreView, measure, pristineCoreView, pristineMeasure } = this.props;
    if (!_.isEmpty(coreView)) {
      return _.isEqual(measure, pristineMeasure) && _.isEqual(coreView, pristineCoreView);
    }
    return _.isEqual(measure, pristineMeasure);
  }

  renderTabList() {
    const { isInsitu, tabs, validation } = this.props;
    let tabList = tabs;
    if (isInsitu) {
      tabList = _.filter(tabList, (tab) => tab.id != 'methods-and-analysis');
    }

    const renderTab = (tab) => {
      const tabAttributes = _.extend(tab, {
        key: tab.id,
        isSelected: tab.id === this.props.activePanel,
        needsAttention: _.some(validation[_.camelCase(tab.id)]),
        onTabNavigation: () => this.props.onTabClick(tab.id)
      });

      return <EditModalTab {...tabAttributes} />;
    };

    return (
      <ul role="tablist" className="nav-tabs measure-edit-modal-tabs">
        {tabList.map(renderTab)}
      </ul>
    );
  }

  renderPanels() {
    const { activePanel, tabs, isInsitu } = this.props;
    let panelList = tabs;
    if (isInsitu) {
      panelList = _.filter(panelList, (panel) => panel.id != 'methods-and-analysis');
    }

    const renderPanel = (tab) => {
      const panelAttributes = _.extend(tab, {
        key: tab.id,
        isSelected: tab.id === activePanel
      });

      return (
        <EditModalPanel {...panelAttributes}>
          <tab.panelComponent />
        </EditModalPanel>
      );
    };

    return (
      <div className="measure-edit-modal-panels">
        {_.map(panelList, renderPanel)}
      </div>
    );
  }

  renderSaveError() {
    return (
      <div className="alert error">
        {I18n.t('save_error', { scope })}
      </div>
    );
  }

  render() {
    const { isEditing, isInsitu, measure, publishedCoreView, saveError, saving } = this.props;

    if (!isEditing) {
      return null;
    }
    const maybeWithdrawApprovalRequestOnComplete = async () => {
      if (!_.isEmpty(publishedCoreView)) {
        const withdrawn = await requireApprovalRequestWithdrawal(this.state.guidance);
        if (!withdrawn) {
          return;
        }
      }

      this.props.onComplete(this.props.measure);
    };

    const hasInvalidShortName = _.isEmpty(measure.metadata?.shortName);

    return (
      <Modal className="measure-edit-modal" fullScreen onDismiss={this.onCancel}>
        <ModalHeader title={I18n.t('title', { scope })} onDismiss={this.onCancel} />
        <ModalContent className="measure-edit-modal-content">
          {this.renderTabList()}
          {this.renderPanels()}
        </ModalContent>
        <ModalFooter className="measure-edit-modal-footer">
          <ConfigurationNotice />
          {saveError && this.renderSaveError()}
          <div className="btn-group">
            <button type="button" className="btn btn-sm btn-default cancel" onClick={this.onCancel}>
              {I18n.t('cancel', { scope })}
            </button>
            <Button
              busy={saving}
              className="done"
              onClick={isInsitu ? this.onComplete : maybeWithdrawApprovalRequestOnComplete}
              size="sm"
              disabled={isInsitu ? hasInvalidShortName : !isEndDateValid(this.props.measure)}
              variant="primary">
              {I18n.t(isInsitu ? 'accept_insitu' : 'accept', { scope })}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  activePanel: PropTypes.string,
  coreView: PropTypes.object,
  dataSourceView: propTypeOrLoadingSentinel(PropTypes.object),
  isEditing: PropTypes.bool,
  isInsitu: PropTypes.bool,
  measure: measurePropType.isRequired,
  pristineCoreView: PropTypes.object,
  pristineMeasure: PropTypes.object,
  publishedCoreView: PropTypes.object,
  saving: PropTypes.bool,
  saveError: PropTypes.bool,
  tabs: PropTypes.array,
  validation: PropTypes.shape({
    calculation: PropTypes.object.isRequired,
    dataSource: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired
  }).isRequired,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  onTabClick: PropTypes.func
};

EditModal.defaultProps = {
  isEditing: false,
  isInsitu: false,
  tabs: [{
    icon: 'info-inverse',
    id: EditTabs.GENERAL_INFO,
    panelComponent: GeneralPanel,
    title: I18n.t('general_info.tab_title', { scope })
  }, {
    icon: 'story',
    id: EditTabs.METHODS_AND_ANALYSIS,
    panelComponent: MethodsPanel,
    title: I18n.t('methods_and_analysis.tab_title', { scope })
  }, {
    icon: 'data',
    id: EditTabs.DATA_SOURCE,
    panelComponent: DataPanel,
    title: I18n.t('data_source.tab_title', { scope })
  }, {
    icon: 'date',
    id: EditTabs.REPORTING_PERIOD,
    panelComponent: ReportingPeriodPanel,
    title: I18n.t('reporting_period.tab_title', { scope })
  }, {
    icon: 'puzzle',
    id: EditTabs.CALCULATION,
    panelComponent: CalculationPanel,
    title: I18n.t('calculation.tab_title', { scope })
  }, {
    icon: 'goal',
    id: EditTabs.TARGETS,
    panelComponent: TargetsPanel,
    title: I18n.t('targets.tab_title', { scope })
  }, {
    icon: 'checkmark-alt',
    id: EditTabs.STATUS,
    panelComponent: StatusPanel,
    title: I18n.t('status.tab_title', { scope })
  }, {
    icon: 'line-chart',
    id: EditTabs.CHART_OPTIONS,
    panelComponent: ChartOptionsPanel,
    title: I18n.t('chart_options.tab_title', { scope })
  }],
  onCancel: _.noop,
  onComplete: _.noop
};

function mapStateToProps(state, props) {
  const { dataSourceView, displayableFilterableColumns, measure } = state.editor;
  const measureFromProps = props.measure;
  const viewFromProps = props.dataSourceView;
  let validation;

  if (props.isInsitu) { // For inSitu measures, we will receive much of the data for editing from the block component
    if (!_.isEmpty(viewFromProps)) {
      state.editor.dataSourceView = viewFromProps;
    }
    if (!_.isEmpty(measureFromProps) && _.isEmpty(_.get(measure, 'dataSourceLensUid'))) {
      validation = validateConfiguration(
        _.get(measureFromProps, 'metricConfig'),
        dataSourceView,
        displayableFilterableColumns
      );

      // For insitu measures the onChange event for shortname will not work properly because it will use the
      // measureFromProps as the source of truth instead new state value from state.editor
      if (!_.isUndefined(measure.metadata?.shortName) && measureFromProps.metadata.shortName !== measure.metadata.shortName) {
        measureFromProps.metadata.shortName = measure.metadata.shortName;
      }

      state.editor.measure = measureFromProps;
    } else {
      validation = validateConfiguration(
        _.get(measure, 'metricConfig'),
        dataSourceView,
        displayableFilterableColumns
      );
    }

    return _.merge({ validation }, state.editor);
  }
  const publishedCoreView = state.view.publishedCoreView ? state.view.publishedCoreView : {};

  validation = validateConfiguration(
    _.get(measure, 'metricConfig'),
    dataSourceView,
    displayableFilterableColumns
  );

  return _.merge({ validation }, state.editor, { publishedCoreView });
}

function mapDispatchToProps(dispatch, props) {
  // When the measures editor is launched from storyteller, we pass in these actions as props
  return bindActionCreators({
    ...(!props.isInsitu) && {onCancel: cancelEditModal},
    onClearState: clearState,
    ...(!props.isInsitu) && {onComplete: saveMeasure},
    onTabClick: setActivePanel
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
