import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'common/components/DatePicker';

const DayDatepicker = ({ disabled, onChangeDate, selectedDate }) => {
  const [date, setSelectedDate] = useState(selectedDate);

  const onChange = (value) => {
    onChangeDate(value);
    setSelectedDate(value);
  };

  const dayDropdownProps = {
    date: date || selectedDate,
    onChangeDate: (value) => onChange(value),
    disabled
  };

  return <DatePicker {...dayDropdownProps} />;
};

DayDatepicker.propTypes = {
  disabled: PropTypes.bool,
  onSelection: PropTypes.func,
  selectedDuration: PropTypes.number
};

export default DayDatepicker;
