import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BigNumber from 'bignumber.js';

import I18n from 'common/i18n';

import withComputedMeasure from 'common/performance_measures/components/withComputedMeasure';
import computedMeasurePropType from 'common/performance_measures/propTypes/computedMeasurePropType';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';
import { formatMeasureValue } from 'common/performance_measures/lib/measureHelpers';

const scope = 'shared.measures_editor.measure.edit_modal.chart_options.calculated_bounds';
export class CalculatedBounds extends Component {
  render() {
    const { dataRequestInFlight, computedMeasure, measure } = this.props;
    const { errors } = computedMeasure;

    if (
      errors.calculationNotConfigured ||
      errors.dataSourceNotConfigured ||
      errors.noReportingPeriodConfigured
    ) {
      return null;
    }

    let maxValue;
    let minValue;

    if (dataRequestInFlight) {
      maxValue = I18n.t('loading', { scope });
      minValue = I18n.t('loading', { scope });
    } else {
      const { series, calculationColumns } = computedMeasure;

      const allValues = _.map(series, _.last);
      const realValues = _.filter(
        allValues,
        (value) => value instanceof BigNumber
      );

      if (_.isEmpty(realValues)) {
        return null;
      }

      maxValue = formatMeasureValue(_.max(realValues), measure, calculationColumns);
      minValue = formatMeasureValue(_.min(realValues), measure, calculationColumns);
    }

    const maxClasses = classNames('calculated-max-value calculated-value', { loading: dataRequestInFlight });
    const minClasses = classNames('calculated-min-value calculated-value', { loading: dataRequestInFlight });

    return (<div className="calculated-bounds configuration-field">
      <div>
        {I18n.t('max_value', { scope })}
        <span className={maxClasses}>{maxValue}</span>
      </div>
      <div>
        {I18n.t('min_value', { scope })}
        <span className={minClasses}>{minValue}</span>
      </div>
    </div>);
  }
}

CalculatedBounds.defaultProps = {
  computedMeasure: {
    series: [],
    errors: {}
  }
};

CalculatedBounds.propTypes = {
  measure: measurePropType,
  computedMeasure: computedMeasurePropType,
  dataRequestInFlight: PropTypes.bool
};

export default withComputedMeasure()(CalculatedBounds);
