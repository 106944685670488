import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

const YearDropdown = ({ disabled, onSelection, selectedYear }) => {
  const scope = 'shared.measures_editor.measure.edit_modal.chart_options.reporting_period_selection';
  const anchorYear = moment().year();

  const yearDropdownProps = {
    id: 'reporting-period-selected-year',
    options: _.map(_.range(anchorYear - 30, anchorYear + 31, 1), (year) => ({
      title: year.toString(),
      value: year
    })),
    placeholder: I18n.t('choose_year', { scope }),
    value: selectedYear,
    onSelection,
    showOptionsBelowHandle: true,
    disabled
  };

  return <Dropdown {...yearDropdownProps} />;
};

YearDropdown.propTypes = {
  disabled: PropTypes.bool,
  onSelection: PropTypes.func.isRequired,
  selectedYear: PropTypes.number
};

export default YearDropdown;
