import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ForgeButton } from '@tylertech/forge-react';

import AssetActionBar from 'common/components/AssetActionBar';
import ToastNotification from 'common/components/ToastNotification';
import I18n from 'common/i18n';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import { copyMeasure } from '../lib/coreServer';
import { openEditModal } from '../actions/editor';

// Wrapper around the AssetActionBar, with extra provisions for the save toast and
// a button to launch the edit modal.
export class MeasuresAssetActionBar extends PureComponent {
  renderSaveToast() {
    const { saveError, showSaveToastMessage } = this.props;

    const toastMessage = saveError
      ? I18n.t('shared.measures_editor.save_error')
      : I18n.t('shared.measures_editor.save_success');

    return (
      <ToastNotification showNotification={showSaveToastMessage} type={saveError ? 'error' : 'success'}>
        <span>{toastMessage}</span>
      </ToastNotification>
    );
  }

  render() {
    const { coreView, editBusy, measure, onClickEdit, user } = this.props;

    const editDraftButtonProps = {
      busy: editBusy,
      className: 'btn-edit',
      dark: 'true',
      key: 'measures-edit-draft-button',
      onClick: onClickEdit,
      variant: 'primary'
    };

    const onCopy = async (name) => {
      const copiedMeasureUid = await copyMeasure(name, measure, coreView);
      window.open(`/d/${copiedMeasureUid}`, '_blank');
    };

    return (
      <AssetActionBar
        editDraftButton={
          <ForgeButton type="outlined">
            <button type="button" {...editDraftButtonProps}>
              {I18n.t('shared.measures_editor.edit')}
            </button>
          </ForgeButton>
        }
        user={user}
        onCopy={onCopy}
        view={coreView}
      >
        {this.renderSaveToast()}
      </AssetActionBar>
    );
  }
}

MeasuresAssetActionBar.propTypes = {
  coreView: PropTypes.shape({
    name: PropTypes.string
  }),
  editBusy: PropTypes.bool,
  measure: measurePropType,
  onClickEdit: PropTypes.func,
  saveError: PropTypes.bool,
  showSaveToastMessage: PropTypes.bool,
  user: PropTypes.object
};

function mapStateToProps(state) {
  const { coreView, measure, saveError, showSaveToastMessage } = state.view;

  const editBusy =
    _.get(measure, 'dataSourceLensUid') &&
    _.get(state.editor, 'measure.dataSourceLensUid') &&
    // dataSourceView == null indicates an error
    // dataSourceView == {} indicates fetching dataset
    !_.isPlainObject(state.editor, 'dataSourceView');
  return {
    coreView,
    editBusy,
    measure,
    saveError,
    showSaveToastMessage,
    user: window.socrata.currentUser
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onClickEdit: openEditModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasuresAssetActionBar);
