import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NumericInput from 'react-numeric-input';

import I18n from 'common/i18n';
import {
  StatusFunctions,
  StatusValues
} from 'common/performance_measures/lib/constants';
import {
  getValidTargets,
  hasOverlappingTargets
} from 'common/performance_measures/lib/measureHelpers';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import Warning from 'common/measures_editor/components/EditModal/Warning';
import ProximityPreview from './ProximityPreview';
import StatusPill from './StatusPill';
import StatusLabelInput from './StatusLabelInput';
import { setActivePanel } from 'common/measures_editor/actions/editor';
import { EditTabs } from 'common/measures_editor/lib/constants';

const scope = 'shared.measures_editor.measure.edit_modal.status.proximity';

export class Proximity extends Component {
  isDisabled() {
    const { measure } = this.props;
    const targets = getValidTargets(measure);
    return _.isEmpty(targets) || hasOverlappingTargets(measure);
  }

  renderWarning() {
    const { measure, openTargetsTab } = this.props;
    const targets = getValidTargets(measure);
    const warningScope = 'shared.measures_editor.measure.edit_modal.status.warning';

    let linkText;
    let text;
    if (_.isEmpty(targets)) {
      linkText = I18n.t('add_target', { scope: warningScope });
      text = I18n.t('no_targets', { scope: warningScope });
    } else if (hasOverlappingTargets(measure)) {
      const targetType = _.head(targets).type;
      linkText = I18n.t('change_targets', { scope: warningScope });
      text = I18n.t(`overlapping_targets.${targetType}`, { scope: warningScope });
    }
    return <Warning linkAction={openTargetsTab} linkText={linkText} text={text} />;
  }

  renderStatusPill(statusType) {
    const {
      measure,
      onProximityTargetToleranceChange,
      onRemoveProximityStatus
    } = this.props;
    const tolerance = _.get(measure, `metricConfig.status.proximity.${statusType}`);
    const target = I18n.t('target', { scope });
    const labels = _.get(measure, 'metricConfig.status.labels');
    const disabled = this.isDisabled();

    const handleClearText = (e) => {
      e.preventDefault();
      onRemoveProximityStatus(statusType);
    };

    const options = [];
    const unitsFromTargetOption = (<span>
      <NumericInput
        id={`${statusType}-tolerance`}
        min={0}
        className="text-input number-input"
        placeholder={statusType === StatusValues.NEAR_TARGET ? null : 0}
        onChange={(valueAsNumber, valueAsString) =>
          onProximityTargetToleranceChange(statusType, valueAsString)}
        value={tolerance || ''}
        disabled={disabled} />
      <label htmlFor={`${statusType}-tolerance`}> {I18n.t('units_from', { scope, target })}</label>
    </span>);
    const labelOverrideOption = (<span>
      {I18n.t(`status_pill.${statusType}.label_override`, { scope })}
      <StatusLabelInput labels={labels} statusValue={statusType} />
    </span>);

    if (statusType !== StatusValues.OFF_TRACK) {
      options.push(unitsFromTargetOption);
    }

    options.push(labelOverrideOption);

    return (<StatusPill
      disabled={disabled}
      handleClearText={handleClearText}
      statusType={statusType}
      options={_.compact(options)}
      preview={(<ProximityPreview {...this.props} statusType={statusType} isOnPill />)}
      statusFunction={StatusFunctions.PROXIMITY} />);
  }

  render() {
    const disabled = this.isDisabled();
    const classes = classNames('proximity', { disabled });

    return (
      <div className={classes}>
        {disabled && this.renderWarning()}
        <h3>{I18n.t('title', { scope })}</h3>
        <div className="preview-summary">
          <ProximityPreview
            {...this.props}
            statusType={StatusValues.ON_TRACK} />
          <ProximityPreview
            {...this.props}
            statusType={StatusValues.NEAR_TARGET} />
          <ProximityPreview
            {...this.props}
            statusType={StatusValues.OFF_TRACK} />
        </div>
        <div>
          {this.renderStatusPill(StatusValues.ON_TRACK)}
          {this.renderStatusPill(StatusValues.NEAR_TARGET)}
          {this.renderStatusPill(StatusValues.OFF_TRACK)}
        </div>
      </div>
    );
  }
}

Proximity.propTypes = {
  labels: PropTypes.objectOf(PropTypes.string),
  measure: measurePropType.isRequired,
  openTargetsTab: PropTypes.func,
  onRemoveProximityStatus: PropTypes.func.isRequired,
  onProximityTargetToleranceChange: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    openTargetsTab: () => setActivePanel(EditTabs.TARGETS)
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(Proximity);
