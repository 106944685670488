import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localizeLink } from 'common/locale';
import MetadataTable from 'common/components/MetadataTable';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

// Pane containing generic asset metadata for the measure.
export class MetadataPane extends Component {
  render() {
    if (_.isEmpty(this.props.coreView)) {
      return null;
    }
    const { coreView, customMetadataFieldsets } = this.props;
    return (
      <div className="pane" data-pane="metadata">
        <MetadataTable
          coreView={coreView}
          header={null}
          localizeLink={localizeLink}
          customMetadataFieldsets={customMetadataFieldsets || []}
        />
      </div>
    );
  }
}

MetadataPane.propTypes = {
  coreView: PropTypes.object,
  measure: measurePropType.isRequired,
  customMetadataFieldsets: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return state.view;
}

export default connect(mapStateToProps)(MetadataPane);
