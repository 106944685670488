import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from 'common/i18n';
import { CalculationTypes } from 'common/performance_measures/lib/constants';
import withComputedMeasure from 'common/performance_measures/components/withComputedMeasure';
import computedMeasurePropType from 'common/performance_measures/propTypes/computedMeasurePropType';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';
import HelperTooltip from 'common/components/HelperTooltip';

import {
  setDateColumn,
  setActivePanel,
  setCalculationType,
  setDecimalPlaces
} from '../../actions/editor';
import { EditTabs } from '../../lib/constants';
import calculationTypes from './calculationTypes';
import ColumnDropdown from './ColumnDropdown';
import CalculationPreview from './CalculationPreview';

const i18nOptions = {
  scope: 'shared.measures_editor.measure.edit_modal'
};

// Configuration panel for methods and analysis.
export class CalculationPanel extends Component {

  renderCalculatorTypeButtons() {
    const { computedMeasure, calculationType, onSetCalculationType, hasDataRequestError, measure } = this.props;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const {
      dataSourceNotConfigured,
      noReportingPeriodConfigured
    } = _.get(computedMeasure, 'errors', {});

    return _.map(CalculationTypes, (type) => {
      let disabledTypes;
      if (reportingPeriod.size === 'day') {
        disabledTypes = {
          AVERAGE: 'average',
          COUNT: 'count',
          RATE: 'rate'
        };
      }


        const isCurrentType = calculationType === type;
        const className = classNames({
          'btn': true,
          'btn-default': !isCurrentType,
          'btn-primary': isCurrentType,
          'btn-disabled': _.includes(disabledTypes, type),
          [`${type}-calculation`]: true,
        });

        return (_.includes(disabledTypes, type) ? (
          <button
            type="button"
            key={type}
            readOnly
            className={className}>
            <span className="disabled-calculation-type-block-label">
              <HelperTooltip
                content={I18n.t('coming_soon', i18nOptions)}
                id={I18n.t(`shared.measures_editor.calculation_types.${type}`)}
              >
                <span>{I18n.t(`shared.measures_editor.calculation_types.${type}`)}</span>
              </HelperTooltip>
            </span>
          </button>
          ) : (
            <button
              type="button"
              key={type}
              className={className}
              disabled={dataSourceNotConfigured || noReportingPeriodConfigured || hasDataRequestError}
              onClick={() => onSetCalculationType(type)}>
              {I18n.t(`shared.measures_editor.calculation_types.${type}`)}
            </button>
          )
        );
      }
    );
  }

  renderConfigLinks() {
    const { computedMeasure, openDataSourceTab, openReportingPeriodTab } = this.props;

    const {
      dataSourceNotConfigured,
      noReportingPeriodConfigured
    } = _.get(computedMeasure, 'errors', {});

    const dataLink = (
      <button
        className="btn btn-inverse btn-primary"
        onClick={openDataSourceTab}>
        {I18n.t('select_dataset', i18nOptions)}
      </button>
    );

    const periodLink = (
      <button
        className="btn btn-inverse btn-primary"
        onClick={openReportingPeriodTab}>
        {I18n.t('set_reporting_period', i18nOptions)}
      </button>
    );

    return (
      <div className="config-links">
        <div className="centerbox">
          <h2>
            {I18n.t('calculation.not_ready', i18nOptions)}
          </h2>
          {dataSourceNotConfigured && dataLink}
          {noReportingPeriodConfigured && periodLink}
        </div>
      </div>
    );
  }

  renderDateColumnChooser() {
    const {
      computedMeasure,
      dateColumnFieldName,
      displayableFilterableColumns,
      measure,
      onSelectDateColumn
    } = this.props;

    const {
      dataSourceNotConfigured,
      noReportingPeriodConfigured
    } = _.get(computedMeasure, 'errors', {});

    const dateColDropdownOptions = {
      columnFieldName: dateColumnFieldName,
      calculationNotConfigured: dataSourceNotConfigured || noReportingPeriodConfigured,
      displayableFilterableColumns,
      labelledBy: 'date-column',
      measure,
      measureArgument: 'dateColumn',
      onSelectColumn: onSelectDateColumn
    };

    return (
      <div className="calculation-panel-reference-date">
        <h5>
          {I18n.t('calculation.reference_date_column_title', i18nOptions)}
        </h5>
        <div className="block-label" id={dateColDropdownOptions.labelledBy}>
          {I18n.t('calculation.reference_date_column_subtitle', i18nOptions)}
        </div>
        <ColumnDropdown {...dateColDropdownOptions} />
      </div>
    );
  }

  renderSpecificCalculator() {
    const { calculationType, computedMeasure } = this.props;

    const {
      dataSourceNotConfigured,
      noReportingPeriodConfigured
    } = _.get(computedMeasure, 'errors', {});
    const props = { calculationNotConfigured: dataSourceNotConfigured || noReportingPeriodConfigured };

    switch (calculationType) {
      case CalculationTypes.AVERAGE:
        return (<calculationTypes.Average {...props} />);
      case CalculationTypes.COUNT:
        return (<calculationTypes.Count {...props} />);
      case CalculationTypes.SUM:
        return (<calculationTypes.Sum {...props} />);
      case CalculationTypes.RATE:
        return (<calculationTypes.Rate {...props} />);
      case CalculationTypes.RECENT:
        return (<calculationTypes.RecentValue {...props} />);
      default:
        throw new Error(`Unknown calculation type: ${calculationType}`);
    }
  }

  // This happens if there is a crash in withComputedMeasure. More common errors like measure
  // misconfigurations are handled via props.computedMeasure.errors.
  renderDataRequestError() {
    return (
      <div className="calculation-panel-data-request-error alert error">
        {I18n.t('calculation.error_calculating', i18nOptions)}
      </div>
    );
  }

  render() {
    const { computedMeasure, hasDataRequestError } = this.props;
    const {
      dataSourceNotConfigured,
      noReportingPeriodConfigured
    } = _.get(computedMeasure, 'errors', {});

    const isReady = computedMeasure && !dataSourceNotConfigured && !noReportingPeriodConfigured;

    const configLinks = (isReady || hasDataRequestError) ? null : this.renderConfigLinks();
    const preview = isReady ? <CalculationPreview /> : null;
    const dataRequestErrorMessage = hasDataRequestError ? this.renderDataRequestError() : null;

    return (
      <div>
        <h3 className="calculation-panel-title">
          {I18n.t('calculation.title', i18nOptions)}
        </h3>
        <p className="calculation-panel-subtitle">
          {I18n.t('calculation.subtitle', i18nOptions)}
        </p>
        {configLinks}
        {dataRequestErrorMessage}
        <div className="calculation-panel-form">
          <form onSubmit={(event) => event.preventDefault()}>
            {preview}
            <div className="calculation-type-selector btn-group">
              {this.renderCalculatorTypeButtons()}
            </div>
            {this.renderDateColumnChooser()}
            {this.renderSpecificCalculator()}
          </form>
        </div>
      </div>
    );
  }
}

CalculationPanel.propTypes = {
  calculationType: PropTypes.string,
  computedMeasure: computedMeasurePropType,
  dateColumnFieldName: PropTypes.string,
  displayableFilterableColumns: PropTypes.arrayOf(PropTypes.shape({
    renderTypeName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
  })),
  measure: measurePropType.isRequired,
  onChangeDecimalPlaces: PropTypes.func.isRequired,
  onSelectDateColumn: PropTypes.func.isRequired,
  onSetCalculationType: PropTypes.func.isRequired,
  openDataSourceTab: PropTypes.func.isRequired,
  openReportingPeriodTab: PropTypes.func.isRequired,
  hasDataRequestError: PropTypes.bool
};

function mapStateToProps(state) {
  const calculationType = _.get(state, 'editor.measure.metricConfig.type');

  const dateColumnFieldName = _.get(state, 'editor.measure.metricConfig.dateColumn');
  const displayableFilterableColumns = _.get(state, 'editor.displayableFilterableColumns');

  return {
    calculationType,
    dateColumnFieldName,
    displayableFilterableColumns,
    measure: state.editor.measure
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSelectDateColumn: setDateColumn,
    onChangeDecimalPlaces: setDecimalPlaces,
    onSetCalculationType: setCalculationType,
    openDataSourceTab: () => setActivePanel(EditTabs.DATA_SOURCE),
    openReportingPeriodTab: () => setActivePanel(EditTabs.REPORTING_PERIOD)
  }, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withComputedMeasure()
)(CalculationPanel);
