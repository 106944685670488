import PropTypes from 'prop-types';
import React from 'react';
import SocrataIcon from 'common/components/SocrataIcon';

const Warning = ({ text, linkText, linkAction }) => {
  const link = linkText && linkAction ? (
    <button className="btn btn-link" onClick={linkAction} type="button">
      {linkText}
    </button>
  ) : null;

  return (
    <div className="validation-warning">
      <SocrataIcon name="warning" />
      <div className="warning-text">{text} {link}</div>
    </div>
  );
};

Warning.propTypes = {
  linkAction: PropTypes.func,
  linkText: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default Warning;
