import { connect } from 'react-redux';

import InfoPaneComponent from 'common/components/InfoPaneComponent';

// This component wraps a common implementation, passing through a configuration
// based on app state.

export function mapStateToProps(state) {
  // Optionally pass through the coreView state. If this is an in-situ measure, they will not be present.
  return {
    ...(!!state.view.coreView.name && {name: state.view.coreView.name}),
    ...(!!state.view.coreView.description && {description: state.view.coreView.description}),
    provenance: null,
    isPaneCollapsible: false
  };
}

export default connect(mapStateToProps)(InfoPaneComponent);
