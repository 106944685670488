import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NumericInput from 'react-numeric-input';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import SocrataIcon from 'common/components/SocrataIcon';
import { PeriodSizes } from 'common/performance_measures/lib/constants';
import reportingPeriodPropType from 'common/performance_measures/propTypes/reportingPeriodPropType';
import DateRange from 'common/performance_measures/lib/dateRange';

import { TargetColumnNames } from 'common/measures_editor/lib/constants';
import QuarterDropdown from './timeSelectors/QuarterDropdown';
import DayDatepicker from './timeSelectors/DayDatepicker';
import WeekDropdown from './timeSelectors/WeekDropdown';
import MonthDropdown from './timeSelectors/MonthDropdown';
import YearDropdown from './timeSelectors/YearDropdown';


const scope = 'shared.measures_editor.measure.edit_modal.targets';

export class TargetsRow extends Component {

  renderMonthDropdown(month) {
    return (
      <MonthDropdown
        selectedMonth={month}
        onSelection={({ value }) => this.props.onColumnChanged(PeriodSizes.MONTH, value)} />
    );
  }

  renderYearDropdown(year) {
    return (
      <YearDropdown
        selectedYear={year}
        onSelection={({ value }) => this.props.onColumnChanged(PeriodSizes.YEAR, value)} />
    );
  }

  /**
    @param selectedDate in YYYY-MM-DD format
  */
  renderWeekDropdown(selectedDate) {
    const { reportingPeriod, onColumnChanged } = this.props;
    const year = moment(selectedDate).year();
    return (
      <WeekDropdown
        targetYear={year}
        reportingPeriod={reportingPeriod}
        onSelection={({ value }) => onColumnChanged(PeriodSizes.WEEK, value)}
        selectedDate={selectedDate} />
    );
  }

  /**
    @param targetQuarterStartMonth {Integer} the first month of the target quarter
  */
  renderQuarterDropdown(targetQuarterStartMonth) {
    const { reportingPeriod: { firstQuarterStartMonth } } = this.props;

    const dropdownProps = {
      firstQuarterStartMonth: firstQuarterStartMonth,
      // value is the first month in the quarter that contains the current target month
      value: targetQuarterStartMonth,
      showOptionsBelowHandle: true,
      // sets month value where value is the first month of the quarter
      onSelection: ({ value }) => this.props.onColumnChanged(PeriodSizes.MONTH, value)
    };

    return (
      <QuarterDropdown {...dropdownProps} />
    );
  }

  /**
    @param selectedDate in YYYY-MM-DD format
  */
  renderDayDatepicker(selectedDate) {
    const { onColumnChanged } = this.props;
    return (
      <DayDatepicker
        onChangeDate={(value) => onColumnChanged(PeriodSizes.DAY, value)}
        selectedDate={selectedDate} />
    );
  }

  render() {
    const { columns, target, reportingPeriod, onColumnChanged, onRemoveClicked } = this.props;
    const startDate = _.get(target, 'startDate');

    const { YEAR, MONTH, WEEK, VALUE, LABEL, QUARTER, DAY } = TargetColumnNames;

    const cells = columns.map((columnName, index) => {
      const onChange = (e) => onColumnChanged(columnName, e.target.value);
      let input = null;

      switch (columnName) {
        case MONTH:
          input = this.renderMonthDropdown(startDate ? moment(startDate).month() : 0);
          break;
        case WEEK:
          input = this.renderWeekDropdown(startDate);
          break;
        case YEAR: {
          // EN-33206: If a reporting period spans the year line, show the end year
          // that will be included in the reporting period
          const endYear = (new DateRange({ start: startDate, size: reportingPeriod.size })).
            inclusiveEnd().year();
          input = this.renderYearDropdown(endYear);
          break;
        }
        case QUARTER:
          input = this.renderQuarterDropdown(startDate ? moment(startDate).month() : 0);
          break;
        case DAY:
          input = this.renderDayDatepicker(startDate);
          break;
        case VALUE: {
          input = (
            <NumericInput
              className="target-input"
              aria-labelledby={`label-${columnName}`}
              value={target.value || ''}
              onChange={(valueAsNumber, valueAsString) => onColumnChanged(columnName, valueAsString)} />
          );
          break;
        }
        case LABEL:
          input = (
            <input
              className="text-input target-input"
              aria-labelledby={`label-${columnName}`}
              type="text"
              value={target.label || ''}
              onChange={onChange}
              maxLength={30} />
          );
          break;
        default:
          throw new Error(`Unknown column for target row: ${columnName}`);
      }

      const className = classNames('targets-cell', columnName);

      return (
        <td className={className} key={index}>
          {input}
        </td>
      );
    });

    return (
      <tr className="targets-row">
        {cells}
        <td>
          <Button
            onClick={onRemoveClicked}
            variant="transparent"
            aria-label={I18n.t('remove_target', { scope })}>
            <SocrataIcon name="close-2" />
          </Button>
        </td>
      </tr>
    );
  }
}

TargetsRow.propTypes = {
  reportingPeriod: reportingPeriodPropType,
  columns: PropTypes.array.isRequired,
  target: PropTypes.object.isRequired,
  onColumnChanged: PropTypes.func.isRequired,
  onRemoveClicked: PropTypes.func.isRequired
};

export default TargetsRow;
