import _ from 'lodash';
import moment from 'moment';

import airbrake from 'common/airbrake';
import { assert, assertIsNumber, assertIsOneOfTypes, assertIsString } from 'common/assertions';
import {
  AxisScalingTypes,
  BlankMeasure,
  CalculationTypes,
  DefaultCumulativeStartDate,
  DefaultStartDateDuration,
  LatestSchemaVersion,
  PeriodSizes,
  StartDateTypes,
  StatusFunctions,
  StatusesOrdered,
  TargetTypes
} from 'common/performance_measures/lib/constants';

import { allowTimelineScopeCurrent } from 'common/performance_measures/lib/measureHelpers';
import {
  getReportingPeriodStartContainingDate,
  getValidTimelineSamplingSizes
} from 'common/performance_measures/lib/reportingPeriods';
import DateRange from 'common/performance_measures/lib/dateRange';

import validate from '../validate';
import actions from '../../actions';
import {
  DENOMINATOR,
  EditTabs,
  LOADING_SENTINEL,
  NUMERATOR,
  TargetColumnNames
} from '../../lib/constants';
import { DATE_FORMAT } from 'common/dates';
import calculateDefaultTargets from '../../lib/calculateDefaultTargets';
import {
  calibrateQuarterlyTargetStartMonths,
  fixXAxisDates,
  conservativeAbsoluteValue
} from './helpers';



const validateActionRegex = /^VALIDATE_/;

// Convenience mutator for the measure being edited.
// warning: _.merge will ignore undefined values so in the scenario where a value is
// 'unset', it is best to explicitly pass in null which _is_ handled by _.merge
const updateMeasureProperty = (state, propertyPath, value) => ({
  ...state,
  measure: _.merge({}, state.measure, _.set({}, propertyPath, value))
});

const setCalculationType = (measure, type) => {
  // Changing type clears almost everything under 'metricConfig'.
  // This is by design.
  assert(
    _.includes(_.values(CalculationTypes), type),
    `Unknown calculation type given: ${type}`
  );

  const existingType = _.get(measure, 'metricConfig.type');
  if (type === existingType) {
    return measure;
  }

  const newMeasure = _.cloneDeep(measure);
  _.set(newMeasure, 'metricConfig', {
    type
  });

  const pathsToKeep = [
    'metricConfig.arguments.isCumulativeMath',
    'metricConfig.display.decimalPlaces',
    'metricConfig.display.shouldDisplayDateRange',
    'metricConfig.display.label',
    'metricConfig.display.pluralLabel',
    'metricConfig.display.yAxis',
    'metricConfig.reportingPeriod',
    'metricConfig.dateColumn',
    'metricConfig.targets',
    'metricConfig.status'
  ];

  // Since we do not allow for cumulative math for Recent Value calculation type
  // the cumulative math stored value should not be kept when switching to Recent
  // Value.
  if (type === CalculationTypes.RECENT) {
    _.remove(pathsToKeep, (path) => {
      return path === 'metricConfig.arguments.isCumulativeMath';
    });
  }

  pathsToKeep.forEach((path) => {
    if (_.has(measure, path)) {
      _.set(newMeasure, path, _.get(measure, path));
    }
  });

  return newMeasure;
};

// Given current state and a UID, returns true if the measure's data
// source has changed (i.e., we should reset the measure calculation).
const dataSourceChanged = (state, uid) => {
  const dataSourceLensUid = _.get(state, 'measure.dataSourceLensUid');

  return (
    (dataSourceLensUid !== uid) // Measure itself changed data source entirely.
  );
};

const resetDataSource = (state) => {
  const { measure } = state;
  const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
  const defaultCalculationType = reportingPeriod.size === 'day' ? CalculationTypes.RECENT : CalculationTypes.AVERAGE;
  const display = _.get(measure, 'metricConfig.display', {});
  const status = _.get(measure, 'metricConfig.status', {});
  const targets = _.get(measure, 'metricConfig.targets', []);

  // TODO: Instead of preserving a list of things to keep, which might grow over
  //       time, we should just remove any fields that need to be cleared.

  const nextState = {
    ...state,
    cachedRowCount: undefined,
    dataSourceView: null,
    displayableFilterableColumns: []
  };

  // Some calculation types, including the defaultCalculationType,
  // have defaults that need to be set.
  const nextMeasure = {
    ...measure,
    dataSourceLensUid: null,
    metricConfig: {
      display,
      reportingPeriod,
      status,
      targets
    }
  };

  return _.set(nextState, 'measure', setCalculationType(nextMeasure, defaultCalculationType));
};

export const DEFAULT_TARGET_TYPE = TargetTypes.ONGOING;

const getNextTarget = (state) => {
  const { targetsType } = state;
  const reportingPeriod = _.get(state, 'measure.metricConfig.reportingPeriod');
  const targets = _.get(state, 'measure.metricConfig.targets');
  try {
    return calculateDefaultTargets({ targetsType, reportingPeriod, targets });
  } catch (error) {
    airbrake.notify({ error });
    return [];
  }
};

const resetToDefaultTargets = (state) => {
  _.set(state, 'measure.metricConfig.targets', []);
  return getNextTarget(state);
};

// Initial state for the edit modal reducer.
export const INITIAL_STATE = Object.freeze({
  isEditing: false,
  activePanel: EditTabs.GENERAL_INFO,
  targetsType: DEFAULT_TARGET_TYPE,
  measure: BlankMeasure,
  pristineMeasure: {},
  pristineCoreView: {},
  validationErrors: validate().validationErrors
});

// Per EN-29231, the "current period" timeline scope is not relevant for period types besides Open.
// This function normalizes a measure config so that the option is only present if relevant.
const fixTimelineScope = (measure) => {
  if (!allowTimelineScopeCurrent(measure)) {
    _.unset(measure, 'metricConfig.display.timelineScope');
  }

  return measure;
};

// Edit modal reducer.
// Governs all form updates, as well as initialize/open and close events.
export default (state = _.cloneDeep(INITIAL_STATE), action) => {
  if (_.isUndefined(action)) {
    return state;
  }

  // Delegate to sub-reducer for validation.
  if (validateActionRegex.test(action.type)) {
    return validate(state, action);
  }

  // Need to cloneDeep the state since react/redux only does a shallow comparison so when we pass a 'measure'
  // prop, changes to nested properties such as 'metric' does not trigger rerendering`
  state = _.cloneDeep(state);

  switch (action.type) {
    case actions.editor.SET_ACTIVE_PANEL: {
      return {
        ...state,
        activePanel: action.panelId
      };
    }

    // Unlike SET_DATA_SOURCE_METADATA_SUCCESS, this is dispatched when a data
    // source is selected by the user or the edit modal is opened.
    // It triggers regardless of whether the data source fetch is successful or not.
    case actions.editor.SET_DATA_SOURCE_UID: {
      // If we have a data source view loaded and it doesn't match the UID in the
      // action, reset the calculation settings. If there is no data source view
      // loaded, don't reset the calculation settings as we're just loading the
      // editor for the first time.
      let newState = dataSourceChanged(state, action.uid) ? resetDataSource(state) : state;
      newState = updateMeasureProperty(newState, 'dataSourceLensUid', action.uid);
      return {
        ...newState,
        // These property values serve as in-flight indicators.
        cachedRowCount: null,
        dataSourceView: LOADING_SENTINEL
      };
    }

    // N.B.: This action is dispatched when the editor is loaded if the measure
    // already has a data source configured. It does not imply the user chose a
    // new data source - we could just be loading an existing measure.
    case actions.editor.SET_DATA_SOURCE_METADATA_SUCCESS: {
      const { uid, rowCount, dataSourceView, displayableFilterableColumns } = action;
      assertIsString(uid);
      assertIsNumber(rowCount);
      assertIsOneOfTypes(dataSourceView, 'object');

      if (dataSourceChanged(state, uid)) {
        // We need to clear out any stale metricConfig data, now that we
        // have a new data source. The easiest way is to call our reset helper
        // function.
        state = resetDataSource(state);
      }

      const { measure } = state;

      return {
        ...state,
        measure: {
          ...measure,
          dataSourceLensUid: uid
        },
        cachedRowCount: rowCount,
        dataSourceView: dataSourceView,
        displayableFilterableColumns: displayableFilterableColumns
      };
    }

    case actions.editor.SET_DATA_SOURCE_METADATA_FAIL:
      return {
        ...state,
        // Unset the in-flight indicators. These values match what gets set
        // during resetDataSource.
        cachedRowCount: undefined,
        dataSourceView: null
      };


    // Default state: no dataset selected
    case actions.editor.RESET_DATA_SOURCE: {
      return resetDataSource(state);
    }

    case actions.editor.SET_CALCULATION_TYPE: {
      const { measure } = state;
      return _.set(state, 'measure', setCalculationType(measure, action.calculationType));
    }

    case actions.editor.SET_COLUMN:
      assertIsOneOfTypes(action.fieldName, 'string');
      return updateMeasureProperty(state, 'metricConfig.arguments.column', action.fieldName);

    case actions.editor.SET_VALUE_COLUMN:
      assertIsOneOfTypes(action.fieldName, 'string');
      return updateMeasureProperty(state, 'metricConfig.arguments.valueColumn', action.fieldName);

    case actions.editor.SET_AGGREGATION_TYPE: {
      assertIsOneOfTypes(action.aggregationType, 'string');
      assert(
        _.get(state, 'measure.metricConfig.type') === CalculationTypes.RATE,
        'This action only makes sense for rate measures today.'
      );

      const newState = updateMeasureProperty(
        state,
        'metricConfig.arguments.aggregationType',
        action.aggregationType
      );

      // Filters and column selections should not be maintained between aggregation types
      _.unset(newState, 'measure.metricConfig.arguments.numeratorColumn');
      _.unset(newState, 'measure.metricConfig.arguments.numeratorFilters');
      _.unset(newState, 'measure.metricConfig.arguments.denominatorColumn');
      _.unset(newState, 'measure.metricConfig.arguments.denominatorFilters');

      return newState;
    }

    case actions.editor.SET_NUMERATOR_COLUMN: {
      assert(
        _.get(state, 'measure.metricConfig.type') === 'rate',
        'This action only makes sense for rate measures.'
      );
      assertIsOneOfTypes(action.fieldName, 'string');
      const newState = updateMeasureProperty(
        state,
        'metricConfig.arguments.numeratorColumn',
        action.fieldName
      );
      _.unset(newState, 'measure.metricConfig.arguments.numeratorColumnCondition');
      return newState;
    }

    case actions.editor.SET_NUMERATOR_COLUMN_CONDITION:
      assert(
        _.get(state, 'measure.metricConfig.type') === 'rate',
        'This action only makes sense for rate measures today.'
      );
      return _.set(state, 'measure.metricConfig.arguments.numeratorColumnCondition', action.condition);

    case actions.editor.SET_DENOMINATOR_COLUMN:
      assertIsOneOfTypes(action.fieldName, 'string');
      // Cant have both Denominator Column and Fixed Denominator
      _.unset(state, 'measure.metricConfig.arguments.fixedDenominator');
      return updateMeasureProperty(state, 'metricConfig.arguments.denominatorColumn', action.fieldName);

    case actions.editor.SET_FIXED_DENOMINATOR:
      _.unset(state, 'measure.metricConfig.arguments.denominatorColumn');
      return updateMeasureProperty(state, 'metricConfig.arguments.fixedDenominator', action.denominator);

    case actions.editor.SET_DATE_COLUMN:
      assertIsOneOfTypes(action.fieldName, 'string');

      return updateMeasureProperty(state, 'metricConfig.dateColumn', action.fieldName);

    case actions.editor.SET_ANALYSIS:
      return updateMeasureProperty(state, 'metadata.analysis', action.analysis);

    case actions.editor.TOGGLE_CUMULATIVE_MATH: {
      const currentValue = _.get(state, 'measure.metricConfig.arguments.isCumulativeMath', false);
      const cumulativeStartDateExist =
        _.get(state, 'measure.metricConfig.arguments.cumulativeMathStartDate', false);
      const startDateType = _.get(state, 'measure.metricConfig.reportingPeriod.startDateConfig.type');

      const nextState =
        updateMeasureProperty(state, 'metricConfig.arguments.isCumulativeMath', !currentValue);

      let startDate;
      if (!cumulativeStartDateExist && !currentValue) {
        if (startDateType === StartDateTypes.FLOATING) {
          startDate = DefaultCumulativeStartDate;
        } else {
          startDate = _.get(state, 'measure.metricConfig.reportingPeriod.startDateConfig.date');
        }

        _.set(nextState, 'measure.metricConfig.arguments.cumulativeMathStartDate', startDate);
      }

      return nextState;
    }

    case actions.editor.TOGGLE_DATE_RANGE: {
      const currentValue = _.get(state, 'measure.metricConfig.display.shouldDisplayDateRange', true);

      return updateMeasureProperty(state, 'metricConfig.display.shouldDisplayDateRange', !currentValue);
    }

    case actions.editor.SET_DECIMAL_PLACES:
      return updateMeasureProperty(state, 'metricConfig.display.decimalPlaces', action.places);

    case actions.editor.SET_UNIT_LABEL: {
      if (action.plural) {
        return updateMeasureProperty(state, 'metricConfig.display.pluralLabel', action.label);
      } else {
        return updateMeasureProperty(state, 'metricConfig.display.label', action.label);
      }
    }

    case actions.editor.TOGGLE_DISPLAY_AS_PERCENT: {
      const currentValue = _.get(state, 'measure.metricConfig.display.asPercent');
      return updateMeasureProperty(state, 'metricConfig.display.asPercent', !currentValue);
    }

    case actions.editor.SET_END_DATE: {
      return updateMeasureProperty(
        state,
        'metricConfig.reportingPeriod.endsBeforeDate',
        action.endsBeforeDate
      );
    }

    case actions.editor.REMOVE_END_DATE: {
      _.unset(state, 'measure.metricConfig.reportingPeriod.endsBeforeDate');
      _.unset(state, 'measure.metricConfig.status.hasMeasureEndStatusOverride');

      return state;
    }

    case actions.editor.TOGGLE_END_DATE_STATUS_OVERRIDE: {
      const hasStatusOverride =
        _.get(state, 'measure.metricConfig.status.hasMeasureEndStatusOverride', false);
      return updateMeasureProperty(
        state,
        'metricConfig.status.hasMeasureEndStatusOverride',
        !hasStatusOverride
      );
    }

    case actions.editor.SET_END_DATE_STATUS_LABEL_OVERRIDE: {
      return updateMeasureProperty(
        state,
        'metricConfig.status.labels.ended',
        action.labelOverride
      );
    }

    case actions.editor.SET_START_DATE: {
      const priorDate = _.get(state, 'measure.metricConfig.reportingPeriod.startDateConfig.date');

      if (action.startDate === priorDate) {
        // No need to update.
        return state;
      }
      let nextState = updateMeasureProperty(
        state,
        'metricConfig.reportingPeriod.startDateConfig.date',
        action.startDate
      );

      _.set(nextState, 'measure.metricConfig.reportingPeriod.startDateConfig.type', 'fixed');

      // Reset targets for period startDate changes if targetsType is PERIODIC
      if (state.targetsType === TargetTypes.PERIODIC) {
        _.set(nextState, 'measure.metricConfig.targets', resetToDefaultTargets(nextState));
      }

      // The end date could become misaligned
      nextState = fixXAxisDates(nextState);

      return nextState;
    }

    case actions.editor.SET_START_DATE_DURATION: {
      const priorDuration = _.get(state, 'measure.metricConfig.reportingPeriod.startDateConfig.duration');

      if (action.startDateDuration === priorDuration) {
        // No need to update.
        return state;
      }
      let nextState = updateMeasureProperty(
        state,
        'metricConfig.reportingPeriod.startDateConfig.duration',
        action.startDateDuration
      );

      _.set(nextState, 'measure.metricConfig.reportingPeriod.startDateConfig.type', 'floating');

      return nextState;
    }

    case actions.editor.SET_QUARTER_START_MONTH: {
      const priorQuarterStartMonth =
        _.get(state, 'measure.metricConfig.reportingPeriod.firstQuarterStartMonth');

      if (action.quarterStartMonth === priorQuarterStartMonth) {
        return state;
      }

      let nextState = updateMeasureProperty(
        state,
        'metricConfig.reportingPeriod.firstQuarterStartMonth',
        action.quarterStartMonth
      );
      nextState = fixXAxisDates(nextState);

      const targets = _.get(nextState, 'measure.metricConfig.targets');
      // if there are targets, make sure they are updated to reflect the change
      // in first month of year change
      if (targets && targets.length) {
        const quarterStartMonth = _.get(
          nextState, 'measure.metricConfig.reportingPeriod.firstQuarterStartMonth'
        );
        const calibratedTargets = calibrateQuarterlyTargetStartMonths(targets, quarterStartMonth);
        _.set(nextState, 'measure.metricConfig.targets', calibratedTargets);
      }

      return nextState;
    }

    case actions.editor.SET_CUMULATIVE_START_DATE: {
      const nextState = updateMeasureProperty(
        state,
        'metricConfig.arguments.cumulativeMathStartDate',
        action.cumulativeStartDate
      );
      return nextState;
    }

    case actions.editor.SET_PERIOD_TYPE: {
      const nextState = updateMeasureProperty(
        state,
        'metricConfig.reportingPeriod.type',
        action.periodType
      );
      fixTimelineScope(nextState.measure);
      return nextState;
    }

    case actions.editor.SET_PERIOD_SIZE: {
      let nextState = updateMeasureProperty(state, 'metricConfig.reportingPeriod.size', action.periodSize);
      const samplingSize = _.get(state.measure, 'metricConfig.display.timelineSampling');
      const validSamplingOptions = getValidTimelineSamplingSizes(action.periodSize);

      if (samplingSize && !_.includes(validSamplingOptions, samplingSize)) {
        nextState = updateMeasureProperty(
          nextState,
          'metricConfig.display.timelineSampling',
          validSamplingOptions[0]
        );
      }

      // Reset targets for period size changes if targetsType is PERIODIC
      if (state.targetsType === TargetTypes.PERIODIC) {
        _.set(nextState, 'measure.metricConfig.targets', resetToDefaultTargets(nextState));
      }

      // Save the first_quarter_start_month configured for this domain if
      // the period size will be quarter. This ensures changing the domain
      // config doesn't alter existing measures.
      const periodSize = _.get(state, 'measure.metricConfig.reportingPeriod.size');
      if (action.periodSize === PeriodSizes.QUARTER) {
        const domainFirstQuarterStartMonth = _.get(window, 'serverConfig.firstQuarterStartMonth', 0);
        _.set(
          nextState,
          'measure.metricConfig.reportingPeriod.firstQuarterStartMonth',
          domainFirstQuarterStartMonth
        );
      } else if (periodSize === PeriodSizes.QUARTER) {
        _.unset(nextState, 'measure.metricConfig.reportingPeriod.firstQuarterStartMonth');
      }

      // Ensure that the x axis start date makes sense for the given reporting period.
      // Note that this should happen _after_ the above logic to set the firstQuarterStartMonth
      if (action.periodSize === PeriodSizes.DAY) {
        _.set(nextState, 'measure.metricConfig.reportingPeriod.startDateConfig.duration', DefaultStartDateDuration);
        _.set(nextState, 'measure.metricConfig.reportingPeriod.startDateConfig.type', StartDateTypes.FLOATING);
        _.set(nextState, 'measure.metricConfig.type', CalculationTypes.RECENT);
        _.unset(nextState, 'measure.metricConfig.reportingPeriod.startDateConfig.date');
        _.unset(nextState, 'measure.metricConfig.reportingPeriod.endsBeforeDate');
      } else {
        nextState = fixXAxisDates(nextState);
      }
      return nextState;
    }

    case actions.editor.SET_TARGETS_TYPE: {
      if (action.targetsType === state.targetsType) {
        return state;
      }
      const nextState = {
        ...state,
        targetsType: action.targetsType
      };
      _.set(nextState, 'measure.metricConfig.targets', resetToDefaultTargets(nextState));

      return nextState;
    }

    case actions.editor.ADD_TARGET: {
      return _.merge({}, state, {
        measure: {
          metricConfig: {
            targets: [
              ...state.measure.metricConfig.targets,
              ...getNextTarget(state)
            ]
          }
        }
      });
    }

    case actions.editor.REMOVE_TARGET: {
      const { measure } = state;
      const { targets } = measure.metricConfig;

      return {
        ...state,
        measure: {
          ...measure,
          metricConfig: {
            ...measure.metricConfig,
            targets: targets.filter((x, i) => i !== action.index)
          }
        }
      };
    }

    case actions.editor.UPDATE_TARGET: {
      const newState = _.cloneDeep(state);
      const { targets } = newState.measure.metricConfig;

      const { index, columnName, value } = action;
      const target = targets[index];
      const { size } = _.get(state, 'measure.metricConfig.reportingPeriod', {});
      const startDate = _.get(state, 'measure.metricConfig.reportingPeriod.startDateConfig.date');
      const targetDate = moment(target.startDate);
      const year = targetDate.year();
      const reportingDate = moment(startDate);

      switch (columnName) {
        case TargetColumnNames.YEAR: {
          // `value` is the end year of the target
          const targetEndDate = (new DateRange({ start: targetDate, size })).inclusiveEnd().year(value).format(DATE_FORMAT);
          const reportingPeriod = _.get(newState, 'measure.metricConfig.reportingPeriod', {});
          // This ensures that we actually have a valid target start date
          target.startDate = getReportingPeriodStartContainingDate(reportingPeriod, targetEndDate);
          break;
        }
        case TargetColumnNames.MONTH:
          // Also used for the first month of a quarter
          target.startDate = reportingDate.year(year).month(value).format(DATE_FORMAT);
          break;
        case TargetColumnNames.WEEK:
          target.startDate = value;
          break;
        case TargetColumnNames.DAY:
          target.startDate = value;
          break;
        default:
          target[columnName] = value;
      }

      return newState;
    }

    case actions.editor.SET_STATUS_TYPE: {
      return updateMeasureProperty(
        state,
        'metricConfig.status.type',
        action.statusType
      );
    }

    case actions.editor.REMOVE_PROXIMITY_STATUS: {
      const newState = _.cloneDeep(state);
      _.unset(newState, `measure.metricConfig.status.proximity.${action.statusValue}`);
      _.unset(newState, `measure.metricConfig.status.labels.${action.statusValue}`);

      return newState;
    }

    case actions.editor.SET_PROXIMITY_TARGET_TOLERANCE:
      return updateMeasureProperty(
        state,
        `metricConfig.status.proximity.${action.statusValue}`,
        conservativeAbsoluteValue(action.tolerance).toString()
      );

    case actions.editor.SET_ABOVE_BELOW_TARGET_TOLERANCE:
      return updateMeasureProperty(
        state,
        'metricConfig.status.above_below.tolerance',
        conservativeAbsoluteValue(action.tolerance).toString()
      );

    case actions.editor.SET_ABOVE_BELOW_DIRECTION:
      return updateMeasureProperty(
        state,
        'metricConfig.status.above_below.direction',
        action.direction
      );

    case actions.editor.TOGGLE_ABOVE_BELOW_INCLUDE_TARGET_VALUE: {
      const currentValue =
        _.get(state, 'measure.metricConfig.status.above_below.include_target', false);
      return updateMeasureProperty(state, 'metricConfig.status.above_below.include_target', !currentValue);
    }

    case actions.editor.SET_STATUS_LABEL_OVERRIDE:
      return updateMeasureProperty(
        state,
        `metricConfig.status.labels.${action.statusValue}`,
        action.labelOverride
      );

    case actions.editor.SET_MANUAL_STATUS_VALUE:
      return updateMeasureProperty(state, 'metricConfig.status.manual', action.statusValue);

    case actions.editor.SET_FILTERS: {
      const { filters, filterTarget } = action;

      switch (filterTarget) {
        case NUMERATOR:
          return _.set(state, 'measure.metricConfig.arguments.numeratorFilters', filters);
        case DENOMINATOR:
          return _.set(state, 'measure.metricConfig.arguments.denominatorFilters', filters);
        default:
          return _.set(state, 'measure.metricConfig.arguments.calculationFilters', filters);
      }
    }

    case actions.editor.SET_METHODS:
      return updateMeasureProperty(state, 'metadata.methods', action.methods);

    // TODO: Investigate reducer cases that are setting paths in state.
    case actions.editor.SET_DESCRIPTION:
      return _.set(state, 'coreView.description', action.description);

    case actions.editor.SET_NAME:
      return _.set(state, 'coreView.name', action.name);

    case actions.editor.SET_SHORT_NAME:
      return _.set(state, 'measure.metadata.shortName', action.shortName);

    case actions.editor.SET_TIMELINE_SCOPE:
      return updateMeasureProperty(state, 'metricConfig.display.timelineScope', action.timelineScope);

    case actions.editor.SET_TIMELINE_SAMPLING:
      return updateMeasureProperty(state, 'metricConfig.display.timelineSampling', action.timelineSampling);

    case actions.editor.OPEN_EDIT_MODAL: {
      const editorDataSourceLensUid = _.get(state, 'measure.dataSourceLensUid');
      const nextState = {
        activePanel: EditTabs.GENERAL_INFO,
        isEditing: true,
        coreView: _.cloneDeep(action.coreView),
        pristineCoreView: _.cloneDeep(action.coreView),
        validationErrors: validate().validationErrors,
        dataSourceView: null,
        isInsitu: _.isEmpty(action.coreView) ? true : false
      };

      // Generally, it makes sense to clear out the state and start fresh when opening the edit modal.
      // However, in the scenario where the user selects a data source, accepts, then opens the edit modal
      // again, we need to re-populate the datasource data
      if (action.viewDataSourceLensUid === editorDataSourceLensUid) {
        nextState.cachedRowCount = state.cachedRowCount;
        nextState.dataSourceView = state.dataSourceView;
        nextState.displayableFilterableColumns = state.displayableFilterableColumns;
      }

      let measure = _.cloneDeep(action.measure);
      // If no calculation type is set, defaults to 'average'
      const currentType = _.get(measure, 'metricConfig.type');
      if (_.isEmpty(currentType)) {
        measure = setCalculationType(measure, CalculationTypes.AVERAGE);
      }
      const startDateType = _.get(measure, 'metricConfig.reportingPeriod.startDateConfig.type');

      const targets = _.get(measure, 'metricConfig.targets');
      if (_.isEmpty(targets)) {
        nextState.targetsType = DEFAULT_TARGET_TYPE;
      } else {
        // Set the targetsType based on the existing targets.
        nextState.targetsType = targets[0].type;
      }

      const measureDefaults = {
        metricConfig: {
          display: {
            decimalPlaces: 2,
            shouldDisplayDateRange: true
          },
          reportingPeriod: {
            startDateConfig: {
              ...(startDateType !== StartDateTypes.FLOATING && { date: moment().startOf('year').format(DATE_FORMAT) }),
              type: StartDateTypes.FIXED
            }
          },
          status: {
            type: StatusFunctions.NONE,
            manual: _.head(StatusesOrdered)
          },
          targets: getNextTarget(nextState)
        }
      };

      const measureWithDefaults = _.defaultsDeep(measure, measureDefaults);

      _.set(nextState, 'measure', _.cloneDeep(measureWithDefaults));
      _.set(nextState, 'pristineMeasure', _.cloneDeep(measureWithDefaults));

      return nextState;
    }

    case actions.editor.CLEAR_STATE:
      const measureDefaults = {
        metricConfig: {
          display: {
            decimalPlaces: 2,
            shouldDisplayDateRange: true
          },
          reportingPeriod: {
            startDateConfig: {
              date: moment().startOf('year').format(DATE_FORMAT),
              type: StartDateTypes.FIXED
            }
          },
          status: {
            type: StatusFunctions.NONE,
            manual: _.head(StatusesOrdered)
          },
          targets: []
        },
        version: LatestSchemaVersion
      };

      const nextMeasure = {
        dataSourceLensUid: null,
        ...measureDefaults
      };

      const nextState = {
        ...state,
        activePanel: EditTabs.GENERAL_INFO,
        cachedRowCount: undefined,
        dataSourceView: null,
        displayableFilterableColumns: [],
        validationErrors: validate().validationErrors
      };
      _.set(nextState, 'measure', setCalculationType(nextMeasure, CalculationTypes.AVERAGE));

      return nextState;

    case actions.editor.SAVE_START:
      return {
        ...state,
        isDirty: true,
        saving: true,
        saveError: false,
        showSaveToastMessage: false
      };

    case actions.editor.SAVE_SUCCESS:
      return {
        ...state,
        isDirty: false,
        isEditing: false,
        saving: false,
        saveError: false,
        showSaveToastMessage: true
      };

    case actions.editor.SAVE_ERROR:
      // Keep the modal open - it's where we show error text.
      return {
        ...state,
        isDirty: true,
        saving: false,
        saveError: true,
        showSaveToastMessage: true
      };

    case actions.editor.CANCEL_EDIT_MODAL:
      return {
        ...state,
        isEditing: false
      };

    case actions.editor.SET_Y_AXIS_SCALING:
      assert(_.includes(AxisScalingTypes, action.scaling));
      return updateMeasureProperty(state, 'metricConfig.display.yAxis.scaling', action.scaling);

    case actions.editor.SET_Y_AXIS_CUSTOM_BOUNDS:
      state = updateMeasureProperty(state, 'metricConfig.display.yAxis.customMax', action.customMax);
      return updateMeasureProperty(state, 'metricConfig.display.yAxis.customMin', action.customMin);

    case actions.editor.SET_TARGET_TERMINOLOGY:
      return updateMeasureProperty(state, 'metricConfig.display.targetTerminology', action.targetTerminology);

    default:
      return state;
  }
};
