import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import formatNumber from 'common/js_utils/formatNumber';
import AssetSelector from 'common/components/AssetSelector';
import * as assetSelectorConstants from 'common/components/AssetBrowser/lib/constants';
import { hasAnyIncomingFederation } from 'common/federation/utils';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';
import { FeatureFlags } from 'common/feature_flags';

import { LOADING_SENTINEL } from '../../lib/constants';
import validateConfiguration from '../../lib/validateConfiguration';
import { changeDataSource, resetDataSource } from '../../actions/editor';

const i18nOptions = { scope: 'shared.measures_editor.measure.edit_modal.data_source' };

// Columns to use for the "list view" asset selector.
const assetSelectorColumns = () => {
  if (hasAnyIncomingFederation()) {
    return [
      assetSelectorConstants.COLUMN_TYPE,
      assetSelectorConstants.COLUMN_NAME,
      assetSelectorConstants.COLUMN_SOURCE,
      assetSelectorConstants.COLUMN_LAST_UPDATED_DATE,
      assetSelectorConstants.COLUMN_OWNER,
      assetSelectorConstants.COLUMN_AUDIENCE,
      assetSelectorConstants.COLUMN_ACTIONS
    ];
  } else {
    return [
      assetSelectorConstants.COLUMN_TYPE,
      assetSelectorConstants.COLUMN_NAME,
      assetSelectorConstants.COLUMN_LAST_UPDATED_DATE,
      assetSelectorConstants.COLUMN_OWNER,
      assetSelectorConstants.COLUMN_AUDIENCE,
      assetSelectorConstants.COLUMN_ACTIONS
    ];
  }
};

// Configuration panel for connecting a measure to a data source.
export class DataPanel extends Component {
  state = { loaded: false };

  handleReset = (event) => {
    event.preventDefault();
    this.props.onResetDataSource();
  };

  renderResetLink() {
    return (
      <button
        className="reset-button"
        onClick={this.handleReset}>
        {I18n.t('reset', i18nOptions)}
      </button>
    );
  }

  // Default state: no dataset selected, rowCount = undefined
  renderSelectionDefaultState() {
    const { rowCount } = this.props;
    if (!_.isUndefined(rowCount)) {
      return null;
    }

    return (
      <div className="selected-dataset alert">
        <p>
          <span className="selected-dataset-label">
            {I18n.t('selected_dataset_label', i18nOptions)}
          </span>
          <span className="selected-dataset-name selected-dataset-empty">
            {I18n.t('selected_dataset_placeholder', i18nOptions)}
          </span>
        </p>
        <p>{I18n.t('message_default', i18nOptions)}</p>
      </div>
    );
  }

  // Fetching state: dataset selected but not yet retrieved, rowCount = null
  renderSelectionFetchingState() {
    return (
      <div className="selected-dataset alert">
        <span className="spinner-default" />
      </div>
    );
  }

  // Valid state: dataset retrieved, rowCount > 0
  renderSelectionValidState() {
    const { dataSourceName, rowCount } = this.props;

    return (
      <div className="selected-dataset alert success">
        <p>
          <span className="selected-dataset-label">
            {I18n.t('selected_dataset_label', i18nOptions)}
          </span>
          <span className="selected-dataset-name">
            {dataSourceName}
          </span>
        </p>
        <p>
          {I18n.t('message_valid', _.merge({ rowCount: formatNumber(rowCount) }, i18nOptions))}
          {' | '}
          {this.renderResetLink()}
        </p>
      </div>
    );
  }

  // Empty state: dataset retrieved, rowCount === 0
  renderSelectionEmptyState() {
    const { dataSourceName } = this.props;
    return (
      <div className="selected-dataset alert warning">
        <p>
          <span className="selected-dataset-label">
            {I18n.t('selected_dataset_label', i18nOptions)}
          </span>
          <span className="selected-dataset-name">
            {dataSourceName}
          </span>
        </p>
        <p>{I18n.t('message_empty', i18nOptions)} | {this.renderResetLink()}</p>
      </div>
    );
  }

  // Invalid state: dataset retrieval failed, or missing date column
  renderSelectionInvalidState() {
    const { errors } = this.props;

    let errorMsg;
    if (errors.badDataSource) {
      errorMsg = I18n.t('message_not_synced_html', i18nOptions);
    } else if (errors.onlyDateColumnHasTimezone) {
      errorMsg = I18n.t('message_time_zone_column', i18nOptions);
    } else if (errors.noDateColumn) {
      errorMsg = I18n.t('message_no_date_column', i18nOptions);
    }

    return (
      <div className="selected-dataset alert error">
        <p dangerouslySetInnerHTML={{ __html: errorMsg }} />
      </div>
    );
  }

  renderAssetSelector() {
    var types = 'datasets,filters,system_datasets';

    const assetSelectorProps = {
      assetSelector: true,
      baseFilters: {
        assetTypes: types,
        published: true,
        source: null // allows all federated assets to appear
      },
      columns: assetSelectorColumns(),
      onAssetSelected: (assetData) => {
        const { measure, onChangeDataSource } = this.props;
        const { dataSourceLensUid } = measure || {};

        if (assetData.id !== dataSourceLensUid) {
          onChangeDataSource(assetData.id);
        }
      },
      openInNewTab: true,
      resultsPerPage: 8,
      renderInModal: false,
      renderStyle: assetSelectorConstants.RENDER_STYLE_LIST,
      showBackButton: false
    };

    return <AssetSelector {...assetSelectorProps} />;
  }

  render() {
    const { errors, rowCount, dataSourceLoading } = this.props;
    const hasError = _.includes(_.values(errors), true);

    let child;

    if (hasError) {
      child = this.renderSelectionInvalidState();
    } else {
      if (dataSourceLoading) {
        child = this.renderSelectionFetchingState();
      } else if (rowCount > 0) {
        child = this.renderSelectionValidState();
      } else if (rowCount === 0) {
        child = this.renderSelectionEmptyState();
      } else {
        child = this.renderSelectionDefaultState();
      }
    }

    return (
      <div>
        <h3>{I18n.t('title', i18nOptions)}</h3>
        {child}
        {this.renderAssetSelector()}
      </div>
    );
  }
}

DataPanel.propTypes = {
  dataSourceName: PropTypes.string,
  dataSourceLoading: PropTypes.bool,
  rowCount: PropTypes.number,
  measure: measurePropType.isRequired,
  onChangeDataSource: PropTypes.func,
  onResetDataSource: PropTypes.func,
  errors: PropTypes.shape({
    badDataSource: PropTypes.bool,
    noDateColumn: PropTypes.bool,
    timezoneDateColumn: PropTypes.bool
  })
};

export function mapStateToProps(state) {
  const { dataSourceView } = state.editor;
  const measure = _.get(state, 'editor.measure', {});
  const dataSourceName = _.get(state, 'editor.dataSourceView.name', '');
  const rowCount = _.get(state, 'editor.cachedRowCount');

  const validation = validateConfiguration(
    _.get(measure, 'metricConfig'),
    _.get(state, 'editor.dataSourceView'),
    _.get(state, 'editor.displayableFilterableColumns')
  );

  const { noDataSource, noDateColumn, onlyDateColumnHasTimezone } = validation.dataSource;
  const badDataSource = noDataSource && !_.isNil(measure.dataSourceLensUid);

  return {
    measure,
    dataSourceLoading: dataSourceView === LOADING_SENTINEL,
    dataSourceName,
    rowCount,
    errors: { noDateColumn, badDataSource, onlyDateColumnHasTimezone }
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onChangeDataSource: changeDataSource,
    onResetDataSource: resetDataSource
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPanel);
