import _ from 'lodash';

import BigNumber from 'bignumber.js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import formatNumber from 'common/js_utils/formatNumber';

import {
  StatusIcons,
  StatusValues
} from 'common/performance_measures/lib/constants';
import { getMeasureNumberFormat } from 'common/performance_measures/lib/measureHelpers';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

const scope = 'shared.measures_editor.measure.edit_modal.status.proximity';

export default class ProximityPreview extends Component {
  render() {
    const { isOnPill, measure, statusType } = this.props;
    const labels = _.get(measure, 'metricConfig.status.labels');
    const statusIcon = StatusIcons[statusType];
    const labelOverride = labels && labels[statusType];
    const labelDefault = I18n.t(`shared.performance_measures.measure.status.values.${statusType}`);
    const label = labelOverride || labelDefault;

    const formatOptions = getMeasureNumberFormat(measure);
    const format = (number) => {
      return formatNumber(Number(number), formatOptions);
    };
    const formatNegative = (number) => {
      return format((new BigNumber(number)).negated().toNumber());
    };

    const onTrack = _.get(measure, `metricConfig.status.proximity.${StatusValues.ON_TRACK}`, 0);
    const nearTarget = _.get(measure, `metricConfig.status.proximity.${StatusValues.NEAR_TARGET}`, 0);
    const offTrack = (new BigNumber(onTrack)).add(nearTarget).toNumber();

    let preview;

    const getShortPreview = (args) => {
      return <span>{I18n.t(`status_pill.${statusType}.preview`, { scope, ...args })}</span>;
    };

    const getUpperLowerPreview = (upperBound, lowerBound) => {
      return (<div className="target-tolerance">
        <p>{I18n.t(`status_pill.${statusType}.upper_bound`, { scope, ...upperBound })}</p>
        <p>{I18n.t(`status_pill.${statusType}.lower_bound`, { scope, ...lowerBound })}</p>
      </div>);
    };

    const getOffTrackDefaultPreview = () => (<span>
      {I18n.t(`status_pill.${statusType}.default`, { scope, zero: format(0) })}
    </span>);

    switch (statusType) {
      case StatusValues.ON_TRACK: {
        preview = getShortPreview({ min: format(onTrack) });
        break;
      }
      case StatusValues.NEAR_TARGET: {
        if (isOnPill) {
          preview = nearTarget ?
            getShortPreview({ max: format(nearTarget) }) :
            null;
          break;
        } else {
          preview = nearTarget ?
            getUpperLowerPreview(
              { min: format(onTrack), max: format(offTrack) },
              { min: formatNegative(onTrack), max: formatNegative(offTrack) }
            ) : null;
        }
        break;
      }
      case StatusValues.OFF_TRACK:
        preview = offTrack ?
          getUpperLowerPreview({ max: format(offTrack) }, { max: formatNegative(offTrack) }) :
          getOffTrackDefaultPreview();
        break;
      default:
        throw new Error(`Unknown status type: ${statusType}`);
    }

    return (
      <div className={`preview-${statusType}`}>
        <h5>
          <SocrataIcon name={statusIcon} />
          <label>{label}</label>
        </h5>
        {preview}
      </div>
    );
  }
}

ProximityPreview.propTypes = {
  isOnPill: PropTypes.bool, // Only used for Near Target status type for isOnPillernative preview
  measure: measurePropType.isRequired,
  statusType: PropTypes.oneOf(_.values(StatusValues)).isRequired
};
