import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import SocrataIcon from 'common/components/SocrataIcon';
import Picklist from 'common/components/Picklist';
import {
  StatusValues,
  StatusFunctions
} from 'common/performance_measures/lib/constants';
import { hasMeasureEnded } from 'common/performance_measures/lib/measureHelpers';

import {
  removeProximityStatus,
  setActivePanel,
  setManualStatusValue,
  setStatusType,
  setProximityTargetTolerance,
  setAboveBelowTargetTolerance
} from 'common/measures_editor/actions/editor';
import { EditTabs } from 'common/measures_editor/lib/constants';

import Proximity from './statusTypes/Proximity';
import AboveBelow from './statusTypes/AboveBelow';
import Manual from './statusTypes/Manual';
import Warning from './Warning';

const scope = 'shared.measures_editor.measure.edit_modal.status';

const picklistOptions = [
  { title: I18n.t(`types.${StatusFunctions.NONE}`, { scope }), value: StatusFunctions.NONE },
  { title: I18n.t(`types.${StatusFunctions.MANUAL}`, { scope }), value: StatusFunctions.MANUAL },
  { title: I18n.t(`types.${StatusFunctions.PROXIMITY}`, { scope }), value: StatusFunctions.PROXIMITY },
  { title: I18n.t(`types.${StatusFunctions.ABOVE_BELOW}`, { scope }), value: StatusFunctions.ABOVE_BELOW }
];

const subpanelMapping = {
  [StatusFunctions.NONE]: () => null,
  [StatusFunctions.MANUAL]: Manual,
  [StatusFunctions.PROXIMITY]: Proximity,
  [StatusFunctions.ABOVE_BELOW]: AboveBelow
};

export class StatusPanel extends Component {

  renderEndDateOverrideWarning() {
    const {
      hasMeasureEndStatusOverride,
      labels,
      measure,
      openDisplayOptionsTab
    } = this.props;

    if (!hasMeasureEndStatusOverride) {
      return null;
    }

    const warningOptions = {
      linkAction: openDisplayOptionsTab,
      linkText: I18n.t('end_date_override.link', { scope })
    };

    const label = _.get(labels, 'ended', '');
    const isEndDatePast = hasMeasureEnded(measure);

    if (isEndDatePast && label) {
      warningOptions.text = I18n.t('end_date_override.past_has_label', { scope, label });
    } else if (isEndDatePast && !label) {
      warningOptions.text = I18n.t('end_date_override.past_no_label', { scope });
    } else if (!isEndDatePast && label) {
      warningOptions.text = I18n.t('end_date_override.future_has_label', { scope, label });
    } else {
      // This is the most reasonable default, and is also the case for
      // end date in the future with no label override
      warningOptions.text = I18n.t('end_date_override.future_no_label', { scope });
    }

    return <Warning {...warningOptions} />;
  }

  renderBody() {
    const {
      onStatusSelected,
      statusType
    } = this.props;

    const picklistSelect = (selected) => {
      onStatusSelected(selected.value);
    };

    const Subpanel = subpanelMapping[statusType];

    return (
      <div className="status-panel-body">
        {I18n.t('select_a_status_type', { scope })}<br />
        <div>
          <div className="column status-function-select">
            <Picklist
              options={picklistOptions}
              value={statusType}
              onChange={picklistSelect}
              onSelection={picklistSelect} />
          </div>
          <div className="column help">
            <p className="heading">
              <SocrataIcon name="question" />
              <span>{I18n.t(`what_is_this.${statusType}.heading`, { scope })}</span>
            </p>
            <p className="body">{I18n.t(`what_is_this.${statusType}.body`, { scope })}</p>
          </div>
        </div>

        <form className="form">
          {this.renderEndDateOverrideWarning()}
          <Subpanel {...this.props} />
        </form>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h3>
          {I18n.t('title', { scope })}
        </h3>
        <div>
          {I18n.t('subtitle', { scope })}
        </div>
        {this.renderBody()}
      </div>
    );
  }
}

StatusPanel.propTypes = {
  type: PropTypes.string,
  enabled: PropTypes.bool,
  statusType: PropTypes.oneOf(_.values(StatusFunctions)),
  hasMeasureEndStatusOverride: PropTypes.bool,
  labels: PropTypes.objectOf(PropTypes.string),
  measure: measurePropType.isRequired,
  value: PropTypes.oneOf(_.values(StatusValues)),
  onToggleEnabled: PropTypes.func.isRequired,
  onManualValueSelected: PropTypes.func,
  onProximityTargetToleranceChange: PropTypes.func,
  onAboveBelowTargetToleranceChange: PropTypes.func,
  onStatusSelected: PropTypes.func,
  openDisplayOptionsTab: PropTypes.func.isRequired
};

StatusPanel.defaultProps = {
  enabled: false,
  value: StatusValues.MEASURING,
  statusType: StatusFunctions.NONE
};

function mapStateToProps(state) {
  const measure = _.get(state, 'editor.measure', {});
  const status = _.get(measure, 'metricConfig.status', {});
  const statusType = _.get(measure, 'metricConfig.status.type', StatusFunctions.NONE);
  return { measure, ...status, statusType };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onToggleEnabled: (e) => setStatusType(
      e.target.checked ? StatusFunctions.MANUAL : StatusFunctions.NONE
    ),
    onStatusSelected: (statusType) => setStatusType(statusType),
    onManualValueSelected: (statusValue) => setManualStatusValue(statusValue),
    onRemoveProximityStatus: (statusValue) => removeProximityStatus(statusValue),
    onProximityTargetToleranceChange: (statusValue, tolerance) =>
      setProximityTargetTolerance(statusValue, tolerance),
    onAboveBelowTargetToleranceChange: (tolerance) =>
      setAboveBelowTargetTolerance(tolerance),
    openDisplayOptionsTab: () => setActivePanel(EditTabs.CHART_OPTIONS)
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusPanel);
