import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';
import reportingPeriodPropType from 'common/performance_measures/propTypes/reportingPeriodPropType';
import DateRange from 'common/performance_measures/lib/dateRange';
import { PeriodSizes } from 'common/performance_measures/lib/constants';

// Calculates a list of all weeks in targetYear which would be valid
// reporting periods based on reportingPeriod startDate
const WeekDropdown = ({ disabled, targetYear, reportingPeriod, onSelection, selectedDate }) => {
  const weeks = [];
  const startDate = _.get(reportingPeriod, 'startDateConfig.date');

  if (targetYear && reportingPeriod && startDate) {
    // Bounds of the target year
    const firstDay = moment({ year: targetYear }).startOf('year');
    const lastDay = moment({ year: targetYear }).endOf('year');
    const reportingPeriodStart = moment(reportingPeriod.startDateConfig.date);

    // Snap weeks to the same day of week starting point.
    let offset = reportingPeriodStart.day() - firstDay.day();
    if (offset < 0) {
      offset += 7;
    }
    const firstWeek = moment(firstDay).add(offset, 'day');
    let week = new DateRange({ start: firstWeek, size: PeriodSizes.WEEK });
    while (week.start.isSameOrBefore(lastDay)) {
      weeks.push({
        title: `${week.start.format('MMM D')} - ${week.inclusiveEnd().format('MMM D')}`,
        value: week.start.format('YYYY-MM-DD')
      });
      week = week.next();
    }
  } else {
    // If we are unable to calculate the options, then display the dropdown
    // disabled regardless of disabled parameter
    disabled = true;
  }

  const scope = 'shared.measures_editor.measure.edit_modal.chart_options.reporting_period_selection';
  const dropdownProps = {
    className: 'week-dropdown',
    disabled,
    options: weeks,
    value: selectedDate,
    showOptionsBelowHandle: true,
    onSelection,
    placeholder: I18n.t('choose_week', { scope })
  };

  return <Dropdown {...dropdownProps} />;
};

WeekDropdown.propTypes = {
  disabled: PropTypes.bool,
  // Year for which to calculate weeks
  targetYear: PropTypes.number,
  // Configured reporting period
  reportingPeriod: reportingPeriodPropType,
  // What to do when a week is selected
  onSelection: PropTypes.func,
  selectedDate: PropTypes.string
};

export default WeekDropdown;
