import { fetchJsonWithDefaultHeaders, urlParams } from 'common/http';

export const createMeasureView = (name) => {
  const deletedAt = urlParams().deleted_at;
  const deletedAtParam = deletedAt ? `?deleted_at=${deletedAt}` : '';
  return fetchJsonWithDefaultHeaders(`/api/views.json${deletedAtParam}`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({
      displayType: 'measure',
      metadata: {
        availableDisplayTypes: ['measure'],
        renderTypeConfig: {
          visible: {
            measure: true
          }
        }
      },
      name
    })
  });
};

export const updateMeasureView = (coreView) => {
  return fetchJsonWithDefaultHeaders(`/api/views/${coreView.id}`, {
    body: JSON.stringify(coreView),
    credentials: 'same-origin',
    method: 'PUT'
  });
};

export const updateMeasureConfiguration = (uid, measure) => {
  return fetchJsonWithDefaultHeaders(`/api/measures_v1/${uid}`, {
    body: JSON.stringify(measure),
    credentials: 'same-origin',
    method: 'PUT'
  });
};

export const updateMeasure = async (coreView, measure) => {
  await updateMeasureView(coreView);
  await updateMeasureConfiguration(coreView.id, measure);
};

export const copyMeasure = async (name, measure, coreView) => {
  const { id } = await createMeasureView(name);
  // add the description to the new measure and update
  const coreViewCopy = { id: id, name: name, description: coreView.description };
  await updateMeasure(coreViewCopy, measure);
  return id;
};
