import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Radiobutton from 'common/components/Radiobutton';
import Button from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import I18n from 'common/i18n';
import { TargetTypes, PeriodSizes } from 'common/performance_measures/lib/constants';
import { hasOverlappingTargets } from 'common/performance_measures/lib/measureHelpers';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import hasUserEnteredTargets from 'common/measures_editor/lib/hasUserEnteredTargets';
import { TargetColumnNames } from 'common/measures_editor/lib/constants';
import { setTargetsType, addTarget, removeTarget, updateTarget, setTargetTerminology } from 'common/measures_editor/actions/editor';
import TargetsRow from './TargetsRow';
import Warning from './Warning';

const { ONGOING, PERIODIC } = TargetTypes;

const scope = 'shared.measures_editor.measure.edit_modal.targets';

export class TargetsPanel extends Component {
  getColumns() {
    const { YEAR, MONTH, WEEK, VALUE, LABEL, QUARTER, DAY } = TargetColumnNames;
    const { type, measure } = this.props;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod');

    const { size } = reportingPeriod;
    const columns = [VALUE, LABEL];

    if (type === ONGOING) {
      return columns;
    }
    if (size === PeriodSizes.YEAR) {
      return [YEAR, ...columns];
    }
    if (size === PeriodSizes.MONTH) {
      return [YEAR, MONTH, ...columns];
    }
    if (size === PeriodSizes.WEEK) {
      return [YEAR, WEEK, ...columns];
    }
    if (size === PeriodSizes.QUARTER) {
      return [QUARTER, YEAR, ...columns];
    }
    if (size === PeriodSizes.DAY) {
      return [DAY, ...columns];
    }
    // Default case is ONGOING
    return columns;
  }

  changeIfConfirmed(type) {
    const { measure } = this.props;
    const targets = _.get(measure, 'metricConfig.targets');

    return () => {
      const message = I18n.t('confirm_change_frequency', { scope });
      if (!hasUserEnteredTargets(targets) || window.confirm(message)) {
        this.props.onTypeSelected(type);
      }
    };
  }

  renderReportingPeriod() {
    const { measure } = this.props;
    const startDate = _.get(measure, 'metricConfig.reportingPeriod.startDateConfig.date');
    const html = I18n.t('period_starts_on_html', {
      scope,
      date: moment(startDate).format('LL')
    });

    return (
      <div dangerouslySetInnerHTML={{ __html: html }} />
    );
  }

  renderTable() {
    const {
      measure,
      type,
      onRemoveClicked,
      onTargetUpdated
    } = this.props;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod');
    const targets = _.get(measure, 'metricConfig.targets');
    const columns = this.getColumns();

    const warningText = I18n.t(`smart_status_warning.${type}`, { scope });
    const warning = hasOverlappingTargets(measure) && <Warning text={warningText} />;

    const tableHeader = (
      <thead>
        <tr>
          {columns.map((columnName) => (
            <th
              id={`label-${columnName}`}
              key={columnName}
              className={columnName}>
              {I18n.t(`columns.${columnName}`, { scope })}
            </th>
          ))}
          <td className="remove-target">&nbsp;</td>
        </tr>
        <tr className="targets-sublabel-row">
          {columns.map((columnName, index) => (
            <td key={index}>
              {
                columnName === 'label' ?
                  <span className="sublabel">{I18n.t('columns.label_sublabel', { scope })}</span> : null
              }
            </td>
          ))}
          <td></td>
        </tr>
      </thead>
    );

    const tableBody = (
      <tbody>
        {targets.map((target, index) =>
          <TargetsRow
            target={target}
            columns={columns}
            reportingPeriod={reportingPeriod}
            key={`target-${index}`}
            onColumnChanged={(columnName, value) => onTargetUpdated(index, columnName, value)}
            onRemoveClicked={() => onRemoveClicked(index)} />
        )}
      </tbody>
    );

    return (
      <section className="target-values measure-edit-modal-section">
        <h4 className="h5">
          {I18n.t('target_values', { scope })}
        </h4>
        {warning}
        <table className="targets-table">
          {tableHeader}
          {tableBody}
        </table>
      </section>
    );
  }

  renderAddButton() {
    const { type, measure, onAddClicked } = this.props;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod');
    let label;

    if (type === ONGOING) {
      label = I18n.t('add_target', { scope });
    } else {
      const { size } = reportingPeriod;
      label = I18n.t(`add_${size}`, { scope });
    }

    return (
      <Button
        id="add-button"
        onClick={onAddClicked}>
        {label}
      </Button>
    );
  }

  renderTargetTerminology() {
    const { measure, onTargetTerminologyUpdated } = this.props;
    const targetTerminology = _.get(measure, 'metricConfig.display.targetTerminology', '');
    return (<section className="measure-edit-modal-section">
      <h4 className="h5">{I18n.t('terminology.title', { scope })}</h4>
      <Form className="configuration-field">
        <Input
          className="target-input"
          label={I18n.t('terminology.label', { scope })}
          name="performance_measures_target_terminology"
          valid
          onChange={(e) => onTargetTerminologyUpdated(e.target.value)}
          value={targetTerminology}
          maxLength={26}></Input>
      </Form>
    </section>);
  }

  render() {
    const { type, measure } = this.props;
    const reportingPeriodSize = _.get(measure, 'metricConfig.reportingPeriod.size');
    const isOngoing = type === ONGOING;

    return (
      <div>
        <h3>
          {I18n.t('title', { scope })}
        </h3>
        <div>
          {I18n.t('subtitle', { scope })}
        </div>
        {/* <form> is required for Radiobutton */}
        <form>
          <Radiobutton
            id="ongoing-radiobutton"
            checked={isOngoing}
            onChange={this.changeIfConfirmed(ONGOING)}
            label={I18n.t('types.ongoing', { scope })}>
          </Radiobutton>
          <Radiobutton
            id="periodic-radiobutton"
            checked={!isOngoing}
            disabled={!reportingPeriodSize}
            onChange={this.changeIfConfirmed(PERIODIC)}
            label={I18n.t(`types.periodic.${reportingPeriodSize}`, { scope })}>
          </Radiobutton>
        </form>

        {!isOngoing && this.renderReportingPeriod()}
        {this.renderTable()}
        {this.renderAddButton()}
        {this.renderTargetTerminology()}
      </div>
    );
  }
}

TargetsPanel.propTypes = {
  measure: measurePropType.isRequired,
  type: PropTypes.oneOf(_.values(TargetTypes)),
  onAddClicked: PropTypes.func.isRequired,
  onRemoveClicked: PropTypes.func.isRequired,
  onTargetUpdated: PropTypes.func.isRequired,
  onTypeSelected: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { editor } = state;
  const { measure, targetsType } = editor;

  return {
    measure,
    type: targetsType
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onTypeSelected: setTargetsType,
    onAddClicked: addTarget,
    onRemoveClicked: removeTarget,
    onTargetUpdated: updateTarget,
    onTargetTerminologyUpdated: setTargetTerminology
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetsPanel);
