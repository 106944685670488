export const CLEAR_SAVE_TOAST = 'CLEAR_SAVE_TOAST';
/*
 * Clears the save toast, optionally after a debounce delay.
 * The `debounce` param controls the time constant of the debounce.
 * As an example, this sets the time constant to 3 seconds:
 * clearSaveToast({ time: 3000 });
 *
 * See the redux-debounced documentation for full details:
 * https://github.com/ryanseddon/redux-debounced/blob/master/README.md
 *
 * If the `debounce` param is omitted, the toast is cleared immediately.
 */
export const clearSaveToast = (debounce) => ({
  type: CLEAR_SAVE_TOAST,
  meta: { debounce } // This is hooking into the react-debounced middleware.
});

export const SET_ACTIVE_PANE = 'SET_ACTIVE_PANE';
export const setActivePane = (activePane) => ({
  type: SET_ACTIVE_PANE,
  activePane
});

export const UPDATE_MEASURE = 'UPDATE_MEASURE';
export const updateMeasure = (measure) => ({
  type: UPDATE_MEASURE,
  measure
});
