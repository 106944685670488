import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import FilterPropType from 'common/propTypes/FilterPropType';
import FilterBar from '../FilterBar';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

export class Count extends Component {
  renderDefinitionText() {
    const scope = 'shared.measures_editor.measure.edit_modal.calculation.types.count';
    return (
      <div className="metric-definition-text">
        <h5>
          {I18n.t('help_title', { scope })}
        </h5>
        {I18n.t('help_body', { scope })}
      </div>
    );
  }

  renderFilterBar() {
    const {
      calculationNotConfigured,
      calculationFilters,
      displayableFilterableColumns
    } = this.props;

    // displayableFilterableColumns is not available until a data source has been selected _and_ its metadata
    // has loaded, but it is required to load the FilterBar.
    if (_.isEmpty(displayableFilterableColumns)) {
      return;
    }

    const filterBarProps = {
      addFilterDisabled: calculationNotConfigured,
      filters: calculationFilters
    };

    return (
      <div className="metric-config">
        <FilterBar {...filterBarProps} />
      </div>
    );
  }

  render() {
    return (
      <div className="metric-container">
        {this.renderFilterBar()}
        {this.renderDefinitionText()}
      </div>
    );
  }
}

Count.defaultProps = {
  calculationNotConfigured: false
};

Count.propTypes = {
  calculationNotConfigured: PropTypes.bool,
  calculationFilters: PropTypes.arrayOf(FilterPropType),
  displayableFilterableColumns: PropTypes.arrayOf(PropTypes.shape({
    renderTypeName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
  })),
  measure: measurePropType.isRequired
};

function mapStateToProps(state) {
  const calculationFilters = _.get(state, 'editor.measure.metricConfig.arguments.calculationFilters');
  const displayableFilterableColumns = _.get(state, 'editor.displayableFilterableColumns');

  return {
    calculationFilters,
    displayableFilterableColumns,
    measure: state.editor.measure
  };
}

export default connect(mapStateToProps)(Count);
