import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NumericInput from 'react-numeric-input';

import I18n from 'common/i18n';
import {
  AboveBelowDirection,
  StatusFunctions,
  StatusValues
} from 'common/performance_measures/lib/constants';
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';
import {
  getValidTargets,
  hasOverlappingTargets
} from 'common/performance_measures/lib/measureHelpers';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import Warning from 'common/measures_editor/components/EditModal/Warning';
import AboveBelowPreview from './AboveBelowPreview';
import StatusPill from './StatusPill';
import StatusLabelInput from './StatusLabelInput';
import {
  setActivePanel,
  setAboveBelowDirection,
  toggleAboveBelowIncludeTargetValue
} from 'common/measures_editor/actions/editor';
import { EditTabs } from 'common/measures_editor/lib/constants';

const scope = 'shared.measures_editor.measure.edit_modal.status.above_below';

export class AboveBelow extends Component {
  constructor(props) {
    super(props);
    this.state = { previewAnimation: false };
  }

  isDisabled() {
    const { measure } = this.props;
    const targets = getValidTargets(measure);
    return _.isEmpty(targets) || hasOverlappingTargets(measure);
  }

  renderWarning() {
    const { measure, openTargetsTab } = this.props;
    const targets = getValidTargets(measure);
    const warningScope = 'shared.measures_editor.measure.edit_modal.status.warning';

    let linkText;
    let text;
    if (_.isEmpty(targets)) {
      linkText = I18n.t('add_target', { scope: warningScope });
      text = I18n.t('no_targets', { scope: warningScope });
    } else if (hasOverlappingTargets(measure)) {
      const targetType = _.head(targets).type;
      linkText = I18n.t('change_targets', { scope: warningScope });
      text = I18n.t(`overlapping_targets.${targetType}`, { scope: warningScope });
    }
    return <Warning linkAction={openTargetsTab} linkText={linkText} text={text} />;
  }

  renderStatusPill(statusType) {
    const {
      measure,
      onChangeAboveBelowDirection,
      onAboveBelowIncludeTargetValue,
      onAboveBelowTargetToleranceChange
    } = this.props;
    const labels = _.get(measure, 'metricConfig.status.labels');
    const disabled = this.isDisabled();
    const direction = _.get(measure, 'metricConfig.status.above_below.direction');
    const otherDirection = direction === AboveBelowDirection.ABOVE ?
      AboveBelowDirection.BELOW : AboveBelowDirection.ABOVE;
    const sizeOnOptionSelected = (option) => {
      onChangeAboveBelowDirection(option.value);
      this.setState({ previewAnimation: true });
      setTimeout(() => this.setState({ previewAnimation: false }), 1000);
    };

    const tolerance = _.get(measure, 'metricConfig.status.above_below.tolerance');
    const isTargetValueIncluded = _.get(measure, 'metricConfig.status.above_below.include_target', false);

    const dropdownAttributes = {
      options: [
        { value: I18n.t(AboveBelowDirection.ABOVE, { scope }),
          title: I18n.t(AboveBelowDirection.ABOVE, { scope }) },
        { value: I18n.t(AboveBelowDirection.BELOW, { scope }),
          title: I18n.t(AboveBelowDirection.BELOW, { scope }) }
      ],
      placeholder: I18n.t('direction_select_default', { scope }),
      value: direction,
      showOptionsBelowHandle: true,
      disabled: disabled,
      onSelection: sizeOnOptionSelected
    };

    const statusDirectionHTML = I18n.t('status_direction_html', {
      scope,
      status: I18n.t(statusType, { scope }),
      direction: I18n.t(otherDirection, { scope })
    });

    const unitFromHTML = I18n.t('units_from', { scope, direction: otherDirection });

    const optionalClasses = classNames({
      'optional': statusType === StatusValues.NEAR_TARGET,
      'unfilled': statusType === StatusValues.NEAR_TARGET && !tolerance
    });

    const options = [];
    const labelOverrideOption = (<span>
      {I18n.t(`status_pill.${statusType}.label_override`, { scope })}
      <StatusLabelInput labels={labels} statusValue={statusType} />
    </span>);
    const directionOption = (<span>
      <span> {I18n.t('relative_direction', { scope })}</span>
      <Dropdown {...dropdownAttributes} />
    </span>);
    const includeTargetOption = (<span>
      <Checkbox
        id="include-target-value"
        disabled={disabled}
        onChange={onAboveBelowIncludeTargetValue}
        checked={isTargetValueIncluded}>
        {I18n.t('include_target_value', { scope })}
      </Checkbox>
    </span>);
    const directionDisplay = (<span>
      {direction ?
        (<span dangerouslySetInnerHTML={{ __html: statusDirectionHTML }} />) :
        (<span><i>{I18n.t('direction_default', { scope })}</i></span>)}
    </span>);
    const unitsFromTargetOption = (<span>
      <NumericInput
        min={0}
        id="target-tolerance"
        className="text-input number-input"
        placeholder={statusType === StatusValues.NEAR_TARGET ? null : 0}
        onChange={(valueAsNumber, valueAsString) => onAboveBelowTargetToleranceChange(valueAsString)}
        value={tolerance || ''}
        disabled={!direction || disabled} />
      {direction ?
        (<label htmlFor="target-tolerance" dangerouslySetInnerHTML={{ __html: unitFromHTML }} />) :
        (<label htmlFor="target-tolerance">{I18n.t('units_from_default', { scope })}</label>)}
    </span>);

    switch (statusType) {
      case StatusValues.ON_TRACK:
        options.push(directionOption);
        options.push(labelOverrideOption);
        options.push(includeTargetOption);
        break;
      case StatusValues.OFF_TRACK:
        options.push(directionDisplay);
        options.push(labelOverrideOption);
        break;
      case StatusValues.NEAR_TARGET:
        options.push(directionDisplay);
        options.push(unitsFromTargetOption);
        options.push(labelOverrideOption);
        break;
      default:
        break;
    }

    const aboveBelowPreview = (
      <AboveBelowPreview
        {...this.props}
        statusType={statusType}
        isOnPill
        previewAnimation={(statusType !== StatusValues.ON_TRACK) && this.state.previewAnimation} />
    );

    return (<StatusPill
      disabled={disabled}
      statusType={statusType}
      optionalClasses={optionalClasses}
      options={_.compact(options)}
      preview={aboveBelowPreview}
      statusFunction={StatusFunctions.ABOVE_BELOW} />);
  }

  render() {
    const { measure } = this.props;
    const disabled = this.isDisabled();
    const tolerance = _.get(measure, 'metricConfig.status.above_below.tolerance');
    const classes = classNames('above-below', { disabled });

    return (
      <div className={classes}>
        {disabled && this.renderWarning()}
        <h3>{I18n.t('title', { scope })}</h3>
        <div className="preview-summary">
          <AboveBelowPreview
            {...this.props}
            statusType={StatusValues.ON_TRACK} />
          <AboveBelowPreview
            {...this.props}
            statusType={StatusValues.OFF_TRACK} />
          {tolerance ? (<AboveBelowPreview
            {...this.props}
            statusType={StatusValues.NEAR_TARGET} />) : (<div></div>)}
        </div>
        <div>
          {this.renderStatusPill(StatusValues.ON_TRACK)}
          {this.renderStatusPill(StatusValues.OFF_TRACK)}
          {this.renderStatusPill(StatusValues.NEAR_TARGET)}
        </div>
      </div>
    );
  }
}

AboveBelow.propTypes = {
  labels: PropTypes.objectOf(PropTypes.string),
  measure: measurePropType.isRequired,
  onChangeAboveBelowDirection: PropTypes.func,
  openTargetsTab: PropTypes.func,
  onAboveBelowIncludeTargetValue: PropTypes.func.isRequired,
  onAboveBelowTargetToleranceChange: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    openTargetsTab: () => setActivePanel(EditTabs.TARGETS),
    onChangeAboveBelowDirection: (direction) => setAboveBelowDirection(direction),
    onAboveBelowIncludeTargetValue: () => toggleAboveBelowIncludeTargetValue()
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(AboveBelow);
