import _ from 'lodash';

import canUserEditMeasure from '../lib/canUserEditMeasure';
import { SaveStates } from '../lib/constants';
import actions from '../actions';
import { toLatestSchemaVersion } from 'common/performance_measures/lib/measureSchemaUpgrader';

// Initial state for the view reducer augments the state passed via ERB.
function stateFromWindow() {
  const canEdit = _.isUndefined(window.socrata.measuresEditor) ? // if the user is creating an inSitu measure, return true.
    true :
    canUserEditMeasure(window.socrata.measuresEditor.coreView);

  const initialState = _.merge({}, window.socrata.measuresEditor, {
    activePane: 'summary',
    saveState: SaveStates.IDLE,
    canEdit: canEdit
  });

  initialState.measure = toLatestSchemaVersion(initialState.measure);

  return initialState;
}

// View reducer.
// Handles on-page navigation, save actions, and updates from the edit modal.
export default (state, action) => {
  if (_.isUndefined(state)) {
    return stateFromWindow();
  }

  if (_.isUndefined(action)) {
    return state;
  }

  switch (action.type) {
    case actions.view.CLEAR_SAVE_TOAST:
      return {
        ...state,
        showSaveToastMessage: false
      };

    case actions.view.SET_ACTIVE_PANE:
      return {
        ...state,
        activePane: action.activePane
      };

    case actions.editor.SAVE_SUCCESS:
      return {
        ...state,
        measure: action.measure,
        coreView: action.coreView
      };

    default:
      return state;
  }
};
