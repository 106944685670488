import canUserEditMeasure from './canUserEditMeasure.js';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { View } from 'common/types/view';
import { assetIsDraft } from 'common/views/helpers';

/**
 * Check if the user can edit the measure (view), the view is not pending approval, and the view is a draft.
 * @param coreView View object
 * @param approvalsGuidance GuidanceSummaryV2
 * @returns boolean
 */
const shouldStartWithEditor = (coreView: Partial<View>, approvalsGuidance: GuidanceSummaryV2): boolean => {
  return !!(
    canUserEditMeasure(coreView) &&
    !withGuidanceV2(approvalsGuidance).isPending() &&
    assetIsDraft({ coreView })
  );
};

export default shouldStartWithEditor;
