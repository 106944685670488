import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import I18n from 'common/i18n';
import DatePicker from 'common/components/DatePicker';
import { PeriodSizes, StartDateTypes } from 'common/performance_measures/lib/constants';
import BlockLabel from 'common/components/BlockLabel';

import QuarterDropdown from '../timeSelectors/QuarterDropdown';
import MonthDropdown from '../timeSelectors/MonthDropdown';
import YearDropdown from '../timeSelectors/YearDropdown';
import DurationDropdown from '../timeSelectors/DurationDropdown';
import Warning from '../Warning';

const i18nOptions = {
  scope: 'shared.measures_editor.measure.edit_modal'
};

export default class XAxisStartSelection extends Component {
  constructor(props) {
    super(props);
    if (props.selectedDate) {
      const selectedDate = moment(props.selectedDate);

      this.state = {
        selectedDate: props.selectedDate,
        selectedDay: selectedDate.date(),
        selectedMonth: selectedDate.month(),
        selectedYear: selectedDate.year()
      };
    } else {
      this.state = {};
    }
  }

  onChangeReportingPeriod = ({ day, month, year }) => {
    const { state } = this;
    const nextState = {
      selectedDay: _.isFinite(day) ? day : state.selectedDay,
      selectedMonth: _.isFinite(month) ? month : state.selectedMonth,
      selectedYear: _.isFinite(year) ? year : state.selectedYear
    };

    if (_(nextState).pick(['selectedDay', 'selectedMonth', 'selectedYear']).every(_.isFinite)) {
      const nextSelectedDate = moment({
        date: nextState.selectedDay,
        month: nextState.selectedMonth,
        year: nextState.selectedYear }).
        format('YYYY-MM-DD');
      nextState.selectedDate = nextSelectedDate;
      this.props.onChangeReportingPeriod(nextSelectedDate);
    }
    this.setState(nextState);
  };

  onChangeDuration = ({ duration }) => {
    const { state } = this;

    const nextState = {
      selectedDuration: _.isFinite(duration) ? duration : state.selectedDuration
    };

    this.props.onChangeDuration(duration);
    this.setState(nextState);
  };

  renderYearDropdown() {
    const { reportingPeriodSize } = this.props;
    const { selectedYear } = this.state;

    return (
      <YearDropdown
        disabled={!reportingPeriodSize}
        selectedYear={selectedYear}
        onSelection={({ value }) =>
          this.onChangeReportingPeriod({
            day: 1,
            year: value,
            type: StartDateTypes.FIXED
          })
        } />
    );
  }

  renderQuarterDropdown() {
    const {
      firstQuarterStartMonth,
      reportingPeriodSize
    } = this.props;

    const quarterDropdownProps = {
      id: 'reporting-period-selected-quarter',
      firstQuarterStartMonth,
      value: this.state.selectedMonth,
      onSelection: ({ value }) =>
        this.onChangeReportingPeriod({
          day: 1,
          month: value,
          type: StartDateTypes.FIXED
        }),
      showOptionsBelowHandle: true,
      disabled: !reportingPeriodSize
    };

    return <QuarterDropdown {...quarterDropdownProps} />;
  }

  renderMonthDropdown() {
    const { reportingPeriodSize } = this.props;
    const { selectedMonth } = this.state;
    return (
      <MonthDropdown
        disabled={!reportingPeriodSize}
        selectedMonth={selectedMonth}
        onSelection={({ value }) =>
          this.onChangeReportingPeriod({
            day: 1,
            month: value,
            type: StartDateTypes.FIXED
          })
        } />
    );
  }

  renderWeekDatePicker() {
    const datePickerProps = {
      date: this.state.selectedDate,
      dateFormat: 'MM/dd/yyyy',
      onChangeDate: (date) => {
        const selectedDate = moment(date);
        this.onChangeReportingPeriod({
          day: selectedDate.date(),
          month: selectedDate.month(),
          year: selectedDate.year(),
          type: StartDateTypes.FIXED
        });
      }
    };

    return <DatePicker {...datePickerProps} />;
  }

  renderDurationDropdown() {
    const { reportingPeriodSize, selectedDuration } = this.props;

    const durationContext = (
      <span className="duration-dropdown-container">
        {I18n.t('days_duration_text', i18nOptions)}
        <DurationDropdown
          disabled={!reportingPeriodSize}
          selectedDuration={this.state.selectedDuration || selectedDuration}
          onSelection={({ value }) =>
            this.onChangeDuration({
              duration: value,
              type: StartDateTypes.FLOATING
            })
          } />
        {I18n.t('days_duration_units', i18nOptions)}
      </span>
    );

    const blockLabelOptions = {
      description: I18n.t('duration_tooltip', i18nOptions),
      title: durationContext
    };

    return (
      <span className="duration-block-label">
        <BlockLabel {...blockLabelOptions} />
      </span>
      );
  }

  renderInputs() {
    const { reportingPeriodSize } = this.props;

    switch (reportingPeriodSize) {
      case PeriodSizes.QUARTER:
        return (
          <div>
            {this.renderQuarterDropdown()}
            {this.renderYearDropdown()}
          </div>
        );
      case PeriodSizes.WEEK:
        return (
          <div>
            {this.renderWeekDatePicker()}
          </div>
        );
      case PeriodSizes.DAY:
        return (
          <div>
            {this.renderDurationDropdown()}
          </div>
        );
      case PeriodSizes.YEAR:
      case PeriodSizes.MONTH:
      default:
        return (
          <div>
            {this.renderMonthDropdown()}
            {this.renderYearDropdown()}
          </div>
        );
    }
  }

  renderValidationWarning() {
    const { validationWarningText } = this.props;
    return _.isEmpty(validationWarningText) ?
      null :
      <Warning text={validationWarningText} />;
  }

  render() {
    const { validationWarningText } = this.props;

    const classes = classNames('reporting-period-selection', {
      'has-validation-warnings': !_.isEmpty(validationWarningText)
    });

    return (
      <div className={classes}>
        {this.renderValidationWarning()}
        {this.renderInputs()}
      </div>
    );
  }
}

XAxisStartSelection.propTypes = {
  firstQuarterStartMonth: PropTypes.number,
  onChangeReportingPeriod: PropTypes.func,
  onChangeDuration: PropTypes.func,
  reportingPeriodSize: PropTypes.string,
  // `selectedDate` should also be passed in as `key`. This will prevent the
  // component's state from getting out of sync with the editor's state
  // by replacing this component every time the `key` prop changes.
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
  selectedDate: PropTypes.string,
  selectedDuration: PropTypes.number,
  validationWarningText: PropTypes.string
};
