import _ from 'lodash';

import { SoqlDataProvider, MetadataProvider } from 'common/visualizations/dataProviders';
import { TRAILING_UID_REGEX } from 'common/http/constants';

import { updateMeasure } from '../lib/coreServer';
import { clearSaveToast } from './view';
import { validateAll } from './validate';

export const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL';
export const setActivePanel = (panelId) => ({
  type: SET_ACTIVE_PANEL,
  panelId
});

export const SET_DATA_SOURCE_UID = 'SET_DATA_SOURCE_UID';
export const setDataSourceUid = (uid) => ({
  type: SET_DATA_SOURCE_UID,
  uid
});

export const SET_DATA_SOURCE_METADATA_SUCCESS = 'SET_DATA_SOURCE_METADATA_SUCCESS';
export const setDataSourceMetadataSuccess =
  (uid, rowCount, dataSourceView, displayableFilterableColumns) => ({
    type: SET_DATA_SOURCE_METADATA_SUCCESS,
    uid,
    rowCount,
    dataSourceView,
    displayableFilterableColumns
  });

export const SET_DATA_SOURCE_METADATA_FAIL = 'SET_DATA_SOURCE_METADATA_FAIL';
export const setDataSourceMetadataFail = () => ({
  type: SET_DATA_SOURCE_METADATA_FAIL
});

// Loads the metadata for the view found at dataSourceLensUid.
export const fetchDataSourceView = (uid) => {
  return async (dispatch, getState) => {
    if (!uid) {
      return;
    }

    if (_.get(getState(), 'editor.dataSourceView.id') === uid) {
      // Already fetched.
      return;
    }

    // Set the uid, regardless of success or failure. This may help recover in
    // the event that a dataset can't be fetched due to being deleted or not yet
    // migrated. We'll rely on the validation logic to indicate that a selection
    // was attempted but not able to be completed.
    dispatch(setDataSourceUid(uid));

    const soqlDataProvider = new SoqlDataProvider({
      domain: window.location.hostname,
      datasetUid: uid
    });

    const metadataProvider = new MetadataProvider({
      domain: window.location.hostname,
      datasetUid: uid
    });

    let metadata = null;
    let columns = [];
    let rowCount = -1;
    try {
      rowCount = await soqlDataProvider.getRowCount();
      metadata = await metadataProvider.getDatasetMetadata();
      columns = await metadataProvider.getDisplayableFilterableColumns({ datasetMetadata: metadata });

      const updateView = dispatch(
        setDataSourceMetadataSuccess(
          uid,
          rowCount,
          metadata,
          columns
        )
      );
      return updateView;
    } catch (ex) {
      console.error(ex);
      dispatch(setDataSourceMetadataFail());
    }
  };
};

export const RESET_DATA_SOURCE = 'RESET_DATA_SOURCE';
export const resetDataSource = () => ({
  type: RESET_DATA_SOURCE
});

export const changeDataSource = (dataSourceString) => {
  return async (dispatch) => {
    const uid = _.get(dataSourceString.match(TRAILING_UID_REGEX), '1');

    dispatch(fetchDataSourceView(uid));
  };
};

export const SET_ANALYSIS = 'SET_ANALYSIS';
export const setAnalysis = (analysis) => ({
  type: SET_ANALYSIS,
  analysis
});

export const SET_CALCULATION_TYPE = 'SET_CALCULATION_TYPE';
export const setCalculationType = (calculationType) => ({
  type: SET_CALCULATION_TYPE,
  calculationType
});

export const SET_COLUMN = 'SET_COLUMN';
export const setColumn = (fieldName) => ({
  type: SET_COLUMN,
  fieldName
});

export const SET_VALUE_COLUMN = 'SET_VALUE_COLUMN';
export const setValueColumn = (fieldName) => ({
  type: SET_VALUE_COLUMN,
  fieldName
});

export const SET_AGGREGATION_TYPE = 'SET_AGGREGATION_TYPE';
export const setAggregationType = (aggregationType) => ({
  type: SET_AGGREGATION_TYPE,
  aggregationType
});

export const SET_NUMERATOR_COLUMN = 'SET_NUMERATOR_COLUMN';
export const setNumeratorColumn = (fieldName) => ({
  type: SET_NUMERATOR_COLUMN,
  fieldName
});

export const SET_NUMERATOR_COLUMN_CONDITION = 'SET_NUMERATOR_COLUMN_CONDITION';
export const setNumeratorColumnCondition = (condition) => ({
  type: SET_NUMERATOR_COLUMN_CONDITION,
  condition
});

export const SET_DENOMINATOR_COLUMN = 'SET_DENOMINATOR_COLUMN';
export const setDenominatorColumn = (fieldName) => ({
  type: SET_DENOMINATOR_COLUMN,
  fieldName
});

export const SET_FIXED_DENOMINATOR = 'SET_FIXED_DENOMINATOR';
export const setFixedDenominator = (denominator) => ({
  type: SET_FIXED_DENOMINATOR,
  denominator
});

export const SET_DATE_COLUMN = 'SET_DATE_COLUMN';
export const setDateColumn = (fieldName) => ({
  type: SET_DATE_COLUMN,
  fieldName
});

export const TOGGLE_CUMULATIVE_MATH = 'TOGGLE_ENABLE_CUMULATIVE_MATH';
export const toggleCumulativeMath = (isCumulativeMath) => ({
  type: TOGGLE_CUMULATIVE_MATH,
  isCumulativeMath
});

export const TOGGLE_DATE_RANGE = 'TOGGLE_INCLUDE_DATE_RANGE';
export const toggleDateRange = (isDateRange) => ({
  type: TOGGLE_DATE_RANGE,
  isDateRange
});

export const SET_DECIMAL_PLACES = 'SET_DECIMAL_PLACES';
export const setDecimalPlaces = (places) => ({
  type: SET_DECIMAL_PLACES,
  places
});

export const TOGGLE_DISPLAY_AS_PERCENT = 'TOGGLE_DISPLAY_AS_PERCENT';
export const toggleDisplayAsPercent = () => ({
  type: TOGGLE_DISPLAY_AS_PERCENT
});

export const SET_UNIT_LABEL = 'SET_UNIT_LABEL';
export const setUnitLabel = (label, plural) => ({
  type: SET_UNIT_LABEL,
  label,
  plural
});

export const SET_END_DATE = 'SET_END_DATE';
export const setEndDate = (endsBeforeDate) => ({
  type: SET_END_DATE,
  endsBeforeDate
});

export const REMOVE_END_DATE = 'REMOVE_END_DATE';
export const removeEndDate = () => ({
  type: REMOVE_END_DATE
});

export const TOGGLE_END_DATE_STATUS_OVERRIDE = 'TOGGLE_END_DATE_STATUS_OVERRIDE';
export const toggleEndDateStatusOverride = () => ({
  type: TOGGLE_END_DATE_STATUS_OVERRIDE
});

export const SET_END_DATE_STATUS_LABEL_OVERRIDE = 'SET_END_DATE_STATUS_LABEL_OVERRIDE';
export const setEndDateStatusLabelOverride = (labelOverride) => ({
  type: SET_END_DATE_STATUS_LABEL_OVERRIDE,
  labelOverride
});

export const SET_START_DATE = 'SET_START_DATE';
export const setStartDate = (startDate) => ({
  type: SET_START_DATE,
  startDate
});

export const SET_START_DATE_DURATION = 'SET_START_DATE_DURATION';
export const setStartDateDuration = (startDateDuration) => ({
  type: SET_START_DATE_DURATION,
  startDateDuration
});

export const SET_QUARTER_START_MONTH = 'SET_QUARTER_START_MONTH';
export const setQuarterStartMonth = (quarterStartMonth) => ({
  type: SET_QUARTER_START_MONTH,
  quarterStartMonth
});

export const SET_CUMULATIVE_START_DATE = 'SET_CUMULATIVE_START_DATE';
export const setCumulativeStartDate = (cumulativeStartDate) => ({
  type: SET_CUMULATIVE_START_DATE,
  cumulativeStartDate
});

export const SET_PERIOD_TYPE = 'SET_PERIOD_TYPE';
export const setPeriodType = (periodType) => ({
  type: SET_PERIOD_TYPE,
  periodType
});

export const SET_PERIOD_SIZE = 'SET_PERIOD_SIZE';
export const setPeriodSize = (periodSize) => ({
  type: SET_PERIOD_SIZE,
  periodSize
});

// Plural TARGETS because you set the type of all targets.
export const SET_TARGETS_TYPE = 'SET_TARGETS_TYPE';
export const setTargetsType = (targetsType) => ({
  type: SET_TARGETS_TYPE,
  targetsType
});

// We don't need any arguments for ADD_TARGET.
// This action just adds an empty target to the list.
export const ADD_TARGET = 'ADD_TARGET';
export const addTarget = () => ({
  type: ADD_TARGET
});

export const REMOVE_TARGET = 'REMOVE_TARGET';
export const removeTarget = (index) => ({
  type: REMOVE_TARGET,
  index
});

export const UPDATE_TARGET = 'UPDATE_TARGET';
export const updateTarget = (index, columnName, value) => ({
  type: UPDATE_TARGET,
  index,
  columnName,
  value
});

export const SET_STATUS_TYPE = 'SET_STATUS_TYPE';
export const setStatusType = (statusType) => ({
  type: SET_STATUS_TYPE,
  statusType
});

export const REMOVE_PROXIMITY_STATUS = 'REMOVE_PROXIMITY_STATUS';
export const removeProximityStatus = (statusValue) => ({
  type: REMOVE_PROXIMITY_STATUS,
  statusValue
});

export const SET_PROXIMITY_TARGET_TOLERANCE = 'SET_PROXIMITY_TARGET_TOLERANCE';
export const setProximityTargetTolerance = (statusValue, tolerance) => ({
  type: SET_PROXIMITY_TARGET_TOLERANCE,
  statusValue,
  tolerance
});

export const SET_ABOVE_BELOW_TARGET_TOLERANCE = 'SET_ABOVE_BELOW_TARGET_TOLERANCE';
export const setAboveBelowTargetTolerance = (tolerance) => ({
  type: SET_ABOVE_BELOW_TARGET_TOLERANCE,
  tolerance
});

export const SET_ABOVE_BELOW_DIRECTION = 'SET_ABOVE_BELOW_DIRECTION';
export const setAboveBelowDirection = (direction) => ({
  type: SET_ABOVE_BELOW_DIRECTION,
  direction
});

export const TOGGLE_ABOVE_BELOW_INCLUDE_TARGET_VALUE = 'TOGGLE_ABOVE_BELOW_INCLUDE_TARGET_VALUE';
export const toggleAboveBelowIncludeTargetValue = () => ({
  type: TOGGLE_ABOVE_BELOW_INCLUDE_TARGET_VALUE
});

export const SET_STATUS_LABEL_OVERRIDE = 'SET_STATUS_LABEL_OVERRIDE';
export const setStatusLabelOverride = (statusValue, labelOverride) => ({
  type: SET_STATUS_LABEL_OVERRIDE,
  statusValue,
  labelOverride
});

export const SET_MANUAL_STATUS_VALUE = 'SET_MANUAL_STATUS_VALUE';
export const setManualStatusValue = (statusValue) => ({
  type: SET_MANUAL_STATUS_VALUE,
  statusValue
});

export const SET_METHODS = 'SET_METHODS';
export const setMethods = (methods) => ({
  type: SET_METHODS,
  methods
});

export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const setDescription = (description) => ({
  type: SET_DESCRIPTION,
  description
});

export const SET_NAME = 'SET_NAME';
export const setName = (name) => ({
  type: SET_NAME,
  name
});

export const SET_SHORT_NAME = 'SET_SHORT_NAME';
export const setShortName = (shortName) => ({
  type: SET_SHORT_NAME,
  shortName
});

export const SET_TIMELINE_SCOPE = 'SET_TIMELINE_SCOPE';
export const setTimelineScope = (timelineScope) => ({
  type: SET_TIMELINE_SCOPE,
  timelineScope
});

export const SET_TIMELINE_SAMPLING = 'SET_TIMELINE_SAMPLING';
export const setTimelineSampling = (timelineSampling) => ({
  type: SET_TIMELINE_SAMPLING,
  timelineSampling
});

export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL';
export const openEditModal = () => async (dispatch, getState) => {
  // Open the edit modal immediately, so a spinner shows (and we don't waste time
  // rendering the expensive InfoPane on loading a draft view, which auto-opens the
  // edit modal on page open).
  const measure = !_.isEmpty(getState().view.measure) ? getState().view.measure : getState().editor.measure;

  const coreView = !_.isEmpty(getState().view.coreView) ? getState().view.coreView : {}; // If the measure is inSitu the coreview will not exist

  const viewDataSourceLensUid = !_.isEmpty(_.get(getState(), 'view.measure.dataSourceLensUid')) ? // If inSitu, there will be no measure saved for view mode
    _.get(getState(), 'view.measure.dataSourceLensUid') :
    _.get(getState(), 'editor.measure.dataSourceLensUid');

  dispatch({
    type: OPEN_EDIT_MODAL,
    coreView,
    measure,
    viewDataSourceLensUid
  });

  // Now fetch data source metadata.
  await fetchDataSourceView(viewDataSourceLensUid)(dispatch, getState);
};

export const CLEAR_STATE = 'CLEAR_STATE';
export const clearState = () => ({
  type: CLEAR_STATE
});

export const SAVE_START = 'SAVE_START';
export const saveStart = () => ({
  type: SAVE_START
});

export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const saveSuccess = (coreView, measure) => ({
  type: SAVE_SUCCESS,
  coreView,
  measure
});

export const SAVE_ERROR = 'SAVE_ERROR';
export const saveError = (error) => ({
  type: SAVE_ERROR,
  error
});

export const SET_FILTERS = 'SET_FILTERS';
export const setFilters = (filters, filterTarget) => ({
  type: SET_FILTERS,
  filters,
  filterTarget
});

export const SET_Y_AXIS_SCALING = 'SET_Y_AXIS_SCALING';
export const setYAxisScaling = (scaling) => ({
  type: SET_Y_AXIS_SCALING,
  scaling
});

export const SET_Y_AXIS_CUSTOM_BOUNDS = 'SET_Y_AXIS_CUSTOM_BOUNDS';
export const setYAxisCustomBounds = (customMax, customMin) => ({
  type: SET_Y_AXIS_CUSTOM_BOUNDS,
  customMax,
  customMin
});

export const SET_TARGET_TERMINOLOGY = 'SET_TARGET_TERMINOLOGY';
export const setTargetTerminology = (targetTerminology) => ({
  type: SET_TARGET_TERMINOLOGY,
  targetTerminology
});

export const saveMeasure = () => {
  return async (dispatch, getState) => {
    dispatch(validateAll());

    const { coreView, measure, validationErrors } = getState().editor;
    const hasErrors = _.some(validationErrors);

    if (hasErrors) {
      return;
    }
    // Saving happens in two requests, because Measures exist in both the ViewsService
    // and MeasuresService.

    // Show the spinner, etc.
    dispatch(saveStart());

    try {
      const viewBody = _.pick(coreView, 'id', 'name', 'description');
      await updateMeasure(viewBody, measure);

      dispatch(saveSuccess(coreView, measure));
    } catch (e) {
      dispatch(saveError(e));
    }

    dispatch(clearSaveToast({ time: 3000 }));
  };
};

export const CANCEL_EDIT_MODAL = 'CANCEL_EDIT_MODAL';
export const cancelEditModal = () => ({
  type: CANCEL_EDIT_MODAL
});
