import _ from 'lodash';
import moment from 'moment';

import {
  PeriodSizes,
  TargetTypes
} from 'common/performance_measures/lib/constants';
import { getStartOfQuarterMonth } from 'common/dates';

/**
  TODO: make a reportingPeriods.periodFor(date) method. see TargetsRow.renderWeekDropdown for duplicated code.
  @param {moment} reportingDate
*/
const calculateDefaultWeeklyTargetDate = (reportingDate) => {
  const now = moment();
  const currentYear = now.year();

  // Snap weeks to the same day of week starting point.
  const firstDay = moment().year(currentYear).month(0).date(1);
  let offset = reportingDate.day() - firstDay.day();
  if (offset < 0) {
    offset += 7;
  }

  const firstWeek = moment(firstDay).add(offset, 'day');

  const date = moment(firstWeek);
  while (date.isBefore(now)) {
    date.add(1, 'week');
  }
  date.add(-1, 'week');

  return date;
};

const getPeriodicTarget = (startDate) => [{
  type: TargetTypes.PERIODIC,
  startDate: startDate.format('YYYY-MM-DD')
}];

/**
  @param {string} size Size of reporting period. Must be one of PeriodSizes.
  @param {string} startDate Start date of reporting period is an object of { date: YYYY-MM-DD, type: fixed or floating }.
  @return {array} Array with one item that is the default target for the given reporting period.
*/
const calculatePeriodicTargetBasedOnToday = ({ size, startDateConfig, firstQuarterStartMonth }) => {
  const currentYear = moment().year();
  const currentMonth = moment().month();
  const date = _.get(startDateConfig, 'date');
  const reportingDate = moment(date);

  let targetDate;

  switch (size) {
    case PeriodSizes.YEAR:
      targetDate = reportingDate.year(currentYear);
      break;
    case PeriodSizes.QUARTER:
      targetDate = reportingDate.year(currentYear)
        .month(getStartOfQuarterMonth(currentMonth, firstQuarterStartMonth));
      break;
    case PeriodSizes.MONTH:
      targetDate = reportingDate.year(currentYear).month(currentMonth);
      break;
    case PeriodSizes.WEEK:
      targetDate = calculateDefaultWeeklyTargetDate(reportingDate);
      break;
    case PeriodSizes.DAY:
      targetDate = reportingDate;
      break;
    default:
      throw new Error(`Unknown period size: ${size}`);
  }

  return getPeriodicTarget(targetDate);
};

const calculatePeriodicTargetBasedOnLastTarget = (reportingPeriod, targets) => {
  const { size } = reportingPeriod;
  const lastTargetStartDate = moment(_.last(targets).startDate);
  return getPeriodicTarget(lastTargetStartDate.add(1, size));
};

const calculateDefaultPeriodicTarget = (reportingPeriod, targets) => {
  if (_.isEmpty(targets)) {
    return calculatePeriodicTargetBasedOnToday(reportingPeriod);
  } else {
    return calculatePeriodicTargetBasedOnLastTarget(reportingPeriod, targets);
  }
};

/**
  @return {array} Array with one item that is the default target for ongoing targets.
*/
const calculateDefaultOngoingTarget = () => [{ type: TargetTypes.ONGOING }];

/**
  Given the type of targets and a reporting period, this will calculate
  an array with a single default target.

  @param {array} targets List of existing targets
  @param {string} targetsType One of TargetTypes.
  @param {object} reportingPeriod With shape { size: string, startDate: string }
*/
export default function calculateDefaultTargets({ targets, targetsType, reportingPeriod }) {
  switch (targetsType) {
    case TargetTypes.ONGOING:
      return calculateDefaultOngoingTarget();
    case TargetTypes.PERIODIC: {
      if (!reportingPeriod) {
        throw new Error('reportingPeriod required for periodic targets');
      }
      return calculateDefaultPeriodicTarget(reportingPeriod, targets);
    }
    default:
      throw new Error(`Unknown targetsType ${targetsType}`);
  }
}
