import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';
import {
  PeriodTypes,
  PeriodSizes,
  TargetTypes
} from 'common/performance_measures/lib/constants';
import Radiobutton from 'common/components/Radiobutton';

import hasUserEnteredTargets from 'common/measures_editor/lib/hasUserEnteredTargets';
import {
  setPeriodType,
  setPeriodSize,
  setQuarterStartMonth
} from 'common/measures_editor/actions/editor';

import QuarterStartMonthOverride from './QuarterStartMonthOverride';

function t(subkey) {
  return I18n.t(`shared.measures_editor.measure.edit_modal.reporting_period.${subkey}`);
}

// Configuration panel for the reporting period start date, type, and size.
export class ReportingPeriodPanel extends Component {
  getPeriodSizesAvailable() {
    const { YEAR, QUARTER, MONTH, WEEK, DAY } = PeriodSizes;
    return [YEAR, QUARTER, MONTH, WEEK, DAY];
  }

  renderSetSize() {
    const { size } = this.props;
    const periodSizesAvailable = this.getPeriodSizesAvailable();

    // returns whether to stop selection
    const sizeOnOptionSelected = (option) => {
      const { targets, targetsType, onChangePeriodSize } = this.props;
      if (
        targetsType === TargetTypes.ONGOING ||
        !hasUserEnteredTargets(targets) ||
        window.confirm(t('change_size_confirm'))
      ) {
        onChangePeriodSize(option.value);
        return true;
      }

      return false;
    };

    const sizeAttributes = {
      firstQuarterStartMonth: this.props.firstQuarterStartMonth,
      onQuarterStartMonthSelected: (option) => this.props.onChangeQuarterStartMonth(option.value)
    };

    const dropdownAttributes = {
      options: periodSizesAvailable.map(sizeValue => ({ value: sizeValue, title: t(`size.${sizeValue}`) })),
      placeholder: size ? t(`size.${size}`) : t('select_size'),
      value: size,
      showOptionsBelowHandle: true,
      onSelection: sizeOnOptionSelected
    };

    return (
      <div className="configuration-field">
        <h5>{t('set_size_label')}</h5>
        <Dropdown {...dropdownAttributes} />
        {size === PeriodSizes.QUARTER ? <QuarterStartMonthOverride {...sizeAttributes} /> : null}
      </div>
    );
  }

  renderReportingPeriodType(PeriodType) {
    const { onChangePeriodType, type } = this.props;

    return (
      <div className="period-type-option-container">
        <div className="left-col">
          {/* <form> is required for Radiobutton */}
          <form>
            <Radiobutton
              id={`period-type-${PeriodType}`}
              checked={type === PeriodType}
              onChange={() => onChangePeriodType(PeriodType)}>
            </Radiobutton>
          </form>
        </div>
        <div className="right-col">
          <label htmlFor={`period-type-${PeriodType}`}>
            <span className="fake-radiobutton" />
            <h6>
              {t(`${PeriodType}_label`)}
            </h6>
          </label>
          <div>
            {t(`${PeriodType}_body`)}
            <div className="reporting-period-size">
              <img
                alt={t(`sample_${PeriodType}`)}
                className="sample-viz"
                src={`/images/measuresEditor/sample-viz-${PeriodType}.svg`} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { OPEN, CLOSED, LAST_REPORTED } = PeriodTypes;

    return (
      <div>
        <h3 className="reporting-period-panel-title">{t('title')}</h3>
        <div onSubmit={(event) => event.preventDefault()}>
          {this.renderSetSize()}
          <div className="configuration-field">
            <h5>{t('type_label')}</h5>
            <div>{t('type_label_description')}</div>
            <div className="period-types">
              {this.renderReportingPeriodType(LAST_REPORTED)}
              {this.renderReportingPeriodType(OPEN)}
              {this.renderReportingPeriodType(CLOSED)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReportingPeriodPanel.propTypes = {
  firstQuarterStartMonth: PropTypes.number,
  onChangePeriodType: PropTypes.func.isRequired,
  onChangePeriodSize: PropTypes.func.isRequired,
  onChangeQuarterStartMonth: PropTypes.func.isRequired,
  size: PropTypes.oneOf(_.values(PeriodSizes)),
  targets: PropTypes.arrayOf(PropTypes.object),
  targetsType: PropTypes.oneOf(_.values(TargetTypes)),
  type: PropTypes.oneOf(_.values(PeriodTypes))
};

function mapStateToProps(state) {
  const reportingPeriod = _.get(state, 'editor.measure.metricConfig.reportingPeriod', {});
  const targets = _.get(state, 'editor.measure.metricConfig.targets');
  const targetsType = _.get(state, 'editor.targetsType');

  return { targets, targetsType, ...reportingPeriod };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onChangePeriodType: setPeriodType,
    onChangePeriodSize: setPeriodSize,
    onChangeQuarterStartMonth: setQuarterStartMonth
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPeriodPanel);
