import _ from 'lodash';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import Radiobutton from 'common/components/Radiobutton';
import SocrataIcon from 'common/components/SocrataIcon';
import {
  StatusIcons,
  StatusValues,
  StatusesOrdered,
  StatusFunctions
} from 'common/performance_measures/lib/constants';

import StatusLabelInput from './StatusLabelInput';

const scope = 'shared.measures_editor.measure.edit_modal.status';

export default class Manual extends Component {

  render() {
    const { labels, onManualValueSelected, manual } = this.props;

    const choices = StatusesOrdered.map((statusValue) => {
      const icon = StatusIcons[statusValue];
      const labelOverride = labels && labels[statusValue];
      const labelDefault = I18n.t(`shared.performance_measures.measure.status.values.${statusValue}`);
      const label = labelOverride || labelDefault;

      return (
        <div key={`status-${statusValue}`} className="status-label-row">
          <div className={`column status status-${statusValue}`}>
            <Radiobutton
              key={statusValue}
              id={`status-${statusValue}-radio`}
              checked={manual === statusValue}
              onChange={() => onManualValueSelected(statusValue)}
              label={(
              <>
                {icon && <SocrataIcon name={icon} /> }
                <span className="status-label">{label}</span>
              </>
              )}
            ></Radiobutton>
          </div>
          <div className="column label-override">
            <label className="block-label block-label-sm" htmlFor={`status-${statusValue}-label`}>
              {I18n.t('label_override', { scope })}
            </label>
            <StatusLabelInput labels={labels} statusValue={statusValue} />
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="status-column-titles">
          <h5 className="column">
            {I18n.t('looks_like', { scope })}
          </h5>
          <div className="column label-override-title">
            {I18n.t('label_override', { scope })}
          </div>
        </div>
        {choices}
      </div>
    );
  }
}

Manual.propTypes = {
  type: PropTypes.oneOf(_.values(StatusFunctions)),
  labels: PropTypes.objectOf(PropTypes.string),
  onManualValueSelected: PropTypes.func,
  manual: PropTypes.oneOf(_.values(StatusValues))
};
