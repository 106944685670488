import moment from 'moment';
import _ from 'lodash';
import { getStartOfQuarterMonth } from 'common/dates';
import {
  PeriodSizes,
  StartDateTypes
} from 'common/performance_measures/lib/constants';

import { getReportingPeriodStartContainingDate } from 'common/performance_measures/lib/reportingPeriods';

export function calibrateQuarterlyTargetStartMonths(targets = [], firstQuarterStartMonth = 0) {
  return _.map(targets, target => {
    if (target.type === 'ongoing' || !target.startDate) {
      return target;
    } else {
      const startDate = moment(target.startDate);
      const startMonth = getStartOfQuarterMonth(startDate.month(), firstQuarterStartMonth);
      startDate.month(startMonth);
      return { ...target, startDate: startDate.format('YYYY-MM-DD') };
    }
  });
}

/**
 * For a given date and reporting period size, get the closest reporting period
 * start date prior to `date`. In other words, get the start date of the reporting
 * period which contains `date`.
 *
 * NOTE: Exported for testing
 */
export const getValidStartDateForSize = (date, reportingPeriodSize, firstQuarterStartMonth) => {
  if (_.isEmpty(date)) {
    return null;
  }

  const monthStart = moment(date).startOf('month');

  let closestStartDate;
  switch (reportingPeriodSize) {
    case PeriodSizes.QUARTER: {
      const quarterStartMonth = getStartOfQuarterMonth(monthStart.month(), firstQuarterStartMonth);
      if (quarterStartMonth !== -1) {
        closestStartDate = monthStart.month(quarterStartMonth);

        // if date is in future, subtract year
        if (closestStartDate.isAfter(date)) {
          closestStartDate = closestStartDate.subtract(1, 'years');
        }
      }
      break;
    }
    case PeriodSizes.YEAR:
    case PeriodSizes.MONTH:
      // Year and month sizes align to the beginning of a month
      closestStartDate = monthStart;
      break;
    case PeriodSizes.WEEK:
    default:
      closestStartDate = moment(date);
      // weeks can start on any day
      break;
  }

  return closestStartDate.format('YYYY-MM-DD');
};

/**
 * Function to handle cases where the existing x-axis start date doesn't work
 * with the rest of the reporting period configuration.
 */
export const fixXAxisDates = (state) => {
  const { measure } = state;
  const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
  const startDate = _.get(reportingPeriod, 'startDateConfig.date');
  const endsBeforeDate = _.get(reportingPeriod, 'endsBeforeDate');
  const periodSize = _.get(reportingPeriod, 'size');
  const firstQuarterStartMonth = _.get(reportingPeriod, 'firstQuarterStartMonth');

  if (!startDate || !periodSize) {
    _.set(measure, 'metricConfig.reportingPeriod.startDateConfig.date', moment().startOf('year').format('YYYY-MM-DD'));
    _.set(measure, 'metricConfig.reportingPeriod.startDateConfig.type', StartDateTypes.FIXED);
    _.unset(measure, 'metricConfig.reportingPeriod.startDateConfig.duration');
    return state;
  }

  const newStartDate = getValidStartDateForSize(startDate, periodSize, firstQuarterStartMonth);
  const newEndDate = getReportingPeriodStartContainingDate(reportingPeriod, endsBeforeDate);

  _.set(measure, 'metricConfig.reportingPeriod.startDateConfig.date', newStartDate);
  _.set(measure, 'metricConfig.reportingPeriod.endsBeforeDate', newEndDate);

  return state;
};

// Convert the value to an absolute value number if it is negative,
// otherwise pass through
export function conservativeAbsoluteValue(value) {
  return value < 0 ? Math.abs(value) : value;
}
