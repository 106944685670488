import _ from 'lodash';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';
import {
  StatusFunctions,
  StatusValues
} from 'common/performance_measures/lib/constants';

export default class StatusPill extends Component {

  render() {
    const {
      disabled,
      handleClearText,
      statusType,
      optionalClasses,
      options,
      preview,
      statusFunction
    } = this.props;

    const scope = `shared.measures_editor.measure.edit_modal.status.${statusFunction}`;

    const title = I18n.t(`status_pill.${statusType}.title`, { scope });
    const description = I18n.t(`status_pill.${statusType}.tooltip`, { scope, defaultValue: false });

    return (
      <div className={`${statusType}-pill status-pill ${optionalClasses}`}>
        <div className="status-header">
          <div className="status-title">
            <h5 className="type">
              <BlockLabel
                title={title}
                htmlFor={title}
                description={description || null} />
            </h5>
          </div>
          {preview}
        </div>
        {options.map((option, index) => (
          <div className="option-item" key={index}>
            <span className="handle">└</span>
            {option}
          </div>))}
        <div>
          {handleClearText ? (<button
            className={`btn btn-link ${disabled ? 'disabled' : ''}`}
            disabled={disabled}
            onClick={handleClearText}>
            {I18n.t('status_pill.clear_input', { scope })}
          </button>) : null}
        </div>
      </div>
    );
  }
}

StatusPill.propTypes = {
  disabled: PropTypes.bool,
  handleClearText: PropTypes.func,
  optionalClasses: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  preview: PropTypes.object,
  statusType: PropTypes.oneOf(_.values(StatusValues)),
  statusFunction: PropTypes.oneOf(_.values(StatusFunctions))
};
