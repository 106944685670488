import _ from 'lodash';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import formatNumber from 'common/js_utils/formatNumber';
import {
  AboveBelowDirection,
  StatusIcons,
  StatusValues
} from 'common/performance_measures/lib/constants';
import { getMeasureNumberFormat } from 'common/performance_measures/lib/measureHelpers';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

const scope = 'shared.measures_editor.measure.edit_modal.status.above_below';

export default class AboveBelowPreview extends Component {

  render() {
    const { measure, statusType, previewAnimation } = this.props;
    const labels = _.get(measure, 'metricConfig.status.labels');
    const statusIcon = StatusIcons[statusType];
    const labelOverride = labels && labels[statusType];
    const labelDefault = I18n.t(`shared.performance_measures.measure.status.values.${statusType}`);
    const label = labelOverride || labelDefault;
    const direction = _.get(measure, 'metricConfig.status.above_below.direction');
    const otherDirection = direction === AboveBelowDirection.ABOVE ?
      AboveBelowDirection.BELOW : AboveBelowDirection.ABOVE;

    const formatOptions = getMeasureNumberFormat(measure);
    const format = (number) => {
      return formatNumber(Number(number), formatOptions);
    };
    const tolerance = _.get(measure, 'metricConfig.status.above_below.tolerance');
    let preview;
    switch (statusType) {
      case StatusValues.ON_TRACK: {
        preview = direction ? (<span>{I18n.t(`${direction}_target`, { scope })}</span>) : null;
        break;
      }
      case StatusValues.NEAR_TARGET: {
        if (tolerance) {
          preview =
          (<span>
            {I18n.t(`distance_${otherDirection}_without_tolerance`, { scope, target: format(tolerance) })}
          </span>);
        } else if (direction) {
          preview = (<span>{I18n.t(`${otherDirection}_target`, { scope })}</span>);
        }
        break;
      }
      case StatusValues.OFF_TRACK:
        if (tolerance && direction) {
          preview =
          (<span>
            {I18n.t(`distance_${otherDirection}_target`, { scope, target: format(tolerance) })}
          </span>);
        } else if (direction) {
          preview = (<span>{I18n.t(`${otherDirection}_target`, { scope })}</span>);
        } else {
          preview = null;
        }
        break;
      default:
        throw new Error(`Unknown status type: ${statusType}`);
    }
    return (
      <div className={`preview-${statusType} ${(previewAnimation) ? direction : ''}`}>
        <h5>
          <SocrataIcon name={statusIcon} />
          <label>{label}</label>
        </h5>
        {preview}
      </div>
    );
  }
}

AboveBelowPreview.propTypes = {
  measure: measurePropType.isRequired,
  statusType: PropTypes.oneOf(_.values(StatusValues)).isRequired,
  // Enables animation when direction is selected
  previewAnimation: PropTypes.bool
};
