import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';
import Checkbox from 'common/components/Checkbox';
import DatePicker from 'common/components/DatePicker';
import MeasureResultCard from 'common/performance_measures/components/MeasureResultCard';
import {
  CalculationTypes,
  DefaultCumulativeStartDate
} from 'common/performance_measures/lib/constants';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';
import NumericInput from 'react-numeric-input';

import {
  toggleCumulativeMath,
  toggleDateRange,
  setUnitLabel,
  setDecimalPlaces,
  setCumulativeStartDate,
  toggleDisplayAsPercent
} from '../../actions/editor';
import { propTypeOrLoadingSentinel } from '../../lib/constants';
import validateConfiguration from '../../lib/validateConfiguration';

// Right hand preview and display options for calculation
export class CalculationPreview extends Component {
  shouldDisableDisplayOptions() {
    const { measure, dataSourceView, displayableFilterableColumns } = this.props;
    const validation = validateConfiguration(
      _.get(measure, 'metricConfig'),
      dataSourceView,
      displayableFilterableColumns
    );
    return validation.calculation.noNumericColumn;
  }

  renderDisplayAsPercent() {
    const {
      calculationType,
      displayAsPercent,
      onToggleDisplayAsPercent
    } = this.props;

    if (calculationType !== CalculationTypes.RATE) {
      return null;
    }

    const displayAsPercentId = 'metric_display_as_percent';
    const displayAsPercentAttributes = {
      id: displayAsPercentId,
      onChange: onToggleDisplayAsPercent,
      checked: displayAsPercent,
      disabled: this.shouldDisableDisplayOptions()
    };

    return (
      <div className="metric-display-as-percent">
        <Checkbox {...displayAsPercentAttributes} >
          {I18n.t('shared.measures_editor.measure.edit_modal.calculation.display_options.display_as_percent')}
        </Checkbox>
      </div>
    );
  }

  render() {
    const {
      onChangeUnitLabel,
      unitLabel,
      pluralUnitLabel,
      onChangeDecimalPlaces,
      decimalPlaces,
      onChangeCumulativeStartDate,
      onToggleCumulativeMath,
      onToggleDateRange,
      measure
    } = this.props;

    const scope = 'shared.measures_editor.measure.edit_modal.calculation';

    const decimalPlacesId = 'metric_decimal_places';
    const decimalPlacesAttributes = {
      id: decimalPlacesId,
      step: 1,
      min: 0,
      max: 6,
      className: 'text-input',
      onChange: (valueAsNumber) => onChangeDecimalPlaces(valueAsNumber),
      value: decimalPlaces,
      disabled: this.shouldDisableDisplayOptions()
    };

    const singularUnitLabelId = 'metric_unit_label_singular';
    const singleUnitLabelAttributes = {
      id: singularUnitLabelId,
      className: 'text-input',
      type: 'text',
      onChange: (event) => onChangeUnitLabel(event.target.value, false),
      placeholder: I18n.t('display_options.unit_label_placeholder', { scope }),
      value: unitLabel,
      disabled: this.shouldDisableDisplayOptions(),
      maxLength: 26
    };

    const pluralUnitLabelId = 'metric_unit_label_plural';
    const pluralUnitLabelAttributes = {
      id: pluralUnitLabelId,
      className: 'text-input',
      type: 'text',
      onChange: (event) => onChangeUnitLabel(event.target.value, true),
      placeholder: I18n.t('display_options.unit_label_placeholder', { scope }),
      value: pluralUnitLabel,
      disabled: this.shouldDisableDisplayOptions(),
      maxLength: 26
    };

    const isCumulativeMath = _.get(
      measure, 'metricConfig.arguments.isCumulativeMath', false);
    const startDate = _.get(measure, 'metricConfig.reportingPeriod.startDateConfig.date');
    const cumulativeStartDate = _.get(measure, 'metricConfig.arguments.cumulativeMathStartDate', startDate || DefaultCumulativeStartDate);

    const isDateRange = _.get(
      measure, 'metricConfig.display.shouldDisplayDateRange', true);

    return (
      <div className="metric-preview">
        <h5>{I18n.t('sample_result', { scope })}</h5>
        <MeasureResultCard measure={measure} showStatus={false} />
        <div className="metric-display-options">
          <div className="metric-decimal-places">
            <label htmlFor={decimalPlacesId}>
              {I18n.t('display_options.decimal_places', { scope })}
            </label>
            <NumericInput {...decimalPlacesAttributes} />
          </div>
          {this.renderDisplayAsPercent()}
          <div className="metric-unit-label">
            <div>
              {I18n.t('display_options.unit_label', { scope })}
            </div>
            <div className="sublabel">
              {I18n.t('display_options.unit_label_sublabel', { scope })}
            </div>

            <div className="metric-unit-label-inputs">
              <div className="metric-unit-label-singular">
                <label htmlFor={singularUnitLabelId}>
                  {I18n.t('display_options.unit_label_singular', { scope })}
                </label>
                <input {...singleUnitLabelAttributes} />
              </div>

              <div className="metric-unit-label-plural">
                <label htmlFor={pluralUnitLabelId}>
                  {I18n.t('display_options.unit_label_plural', { scope })}
                </label>
                <input {...pluralUnitLabelAttributes} />
              </div>
            </div>
          </div>
          <div className="metric-cumulative-math">
            <BlockLabel
              title={I18n.t('cumulative_math.title', { scope })}
              htmlFor={'cumulative-math'}
              description={I18n.t('cumulative_math.tooltip', { scope })} />
            <Checkbox
              id="enable-cumulative-math"
              onChange={onToggleCumulativeMath}
              checked={isCumulativeMath}>
              {I18n.t('cumulative_math.enable_cumulative_math', { scope })}
            </Checkbox>
            <DatePicker
              date={cumulativeStartDate}
              dateFormat={'MM/dd/yyyy'}
              disabled={!isCumulativeMath}
              onChangeDate={onChangeCumulativeStartDate} />
            <Checkbox
              id="disable-date-range"
              onChange={onToggleDateRange}
              checked={isDateRange}>
              {I18n.t('show_date_range', { scope })}
            </Checkbox>
          </div>
        </div>
      </div>
    );
  }
}

CalculationPreview.defaultProps = {
  onToggleDisplayAsPercent: _.noop,
  onToggleCumulativeMath: _.noop,
  onToggleDateRange: _.noop,
  onChangeCumulativeStartDate: _.noop,
  onChangeUnitLabel: _.noop,
  onChangeDecimalPlaces: _.noop,
  displayAsPercent: false
};

CalculationPreview.propTypes = {
  calculationType: PropTypes.string,
  dataSourceView: propTypeOrLoadingSentinel(PropTypes.object),
  decimalPlaces: PropTypes.number,
  displayableFilterableColumns: PropTypes.array,
  displayAsPercent: PropTypes.bool,
  onChangeDecimalPlaces: PropTypes.func,
  onChangeUnitLabel: PropTypes.func,
  onToggleDisplayAsPercent: PropTypes.func,
  onChangeCumulativeStartDate: PropTypes.func,
  onToggleCumulativeMath: PropTypes.func,
  onToggleDateRange: PropTypes.func,
  unitLabel: PropTypes.string,
  pluralUnitLabel: PropTypes.string,
  measure: measurePropType.isRequired
};

export function mapStateToProps(state) {
  const { dataSourceView, displayableFilterableColumns, measure } = state.editor;
  const calculationType = _.get(measure, 'metricConfig.type');
  const decimalPlaces = _.get(measure, 'metricConfig.display.decimalPlaces', 0);
  const displayAsPercent = _.get(measure, 'metricConfig.display.asPercent', false);
  const unitLabel = _.get(measure, 'metricConfig.display.label', '');
  const pluralUnitLabel = _.get(measure, 'metricConfig.display.pluralLabel', '');

  return {
    calculationType,
    dataSourceView,
    decimalPlaces,
    displayableFilterableColumns,
    displayAsPercent,
    unitLabel,
    pluralUnitLabel,
    measure
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onChangeUnitLabel: setUnitLabel,
    onChangeDecimalPlaces: setDecimalPlaces,
    onChangeCumulativeStartDate: setCumulativeStartDate,
    onToggleCumulativeMath: toggleCumulativeMath,
    onToggleDateRange: toggleDateRange,
    onToggleDisplayAsPercent: toggleDisplayAsPercent
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationPreview);
