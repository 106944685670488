import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import NumericInput from 'react-numeric-input';

import I18n from 'common/i18n';
import FilterPropType from 'common/propTypes/FilterPropType';
import formatNumber from 'common/js_utils/formatNumber';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import Dropdown from 'common/components/Dropdown';
import { AccordionContainer, AccordionPane } from 'common/components/Accordion';
import { isColumnUsableWithMeasureArgument } from 'common/performance_measures/measureCalculator';
import withComputedMeasure from 'common/performance_measures/components/withComputedMeasure';
import { CalculationTypes } from 'common/performance_measures/lib/constants';
import { getMeasureNumberFormat } from 'common/performance_measures/lib/measureHelpers';

import { DENOMINATOR, LOADING_SENTINEL, NUMERATOR } from 'common/measures_editor/lib/constants';
import ColumnDropdown from '../ColumnDropdown';
import FilterBar from '../FilterBar';

import {
  setAggregationType,
  setDenominatorColumn,
  setFixedDenominator,
  setNumeratorColumn
} from '../../../actions/editor';

const scope = 'shared.measures_editor.measure.edit_modal.calculation.types.rate';

export class Rate extends Component {
  constructor() {
    super();

    this.state = {};
  }

  renderCalculationFilter(filterTarget) {
    const {
      aggregationType,
      calculationNotConfigured,
      denominatorFilters,
      displayableFilterableColumns,
      measure,
      numeratorFilters
    } = this.props;

    // displayableFilterableColumns is not available until a data source has been selected _and_ its metadata
    // has loaded, but it is required to load the FilterBar.
    if (_.isEmpty(displayableFilterableColumns)) {
      return;
    }

    let filters;

    switch (filterTarget) {
      case NUMERATOR:
        filters = numeratorFilters;
        break;
      case DENOMINATOR:
        filters = denominatorFilters;
        break;
      default:
        console.warn(`Unknown filterTarget for Rate calculation: '${filterTarget}'.`);
    }

    const filterableColumns = _.filter(displayableFilterableColumns, (column) => {
      return isColumnUsableWithMeasureArgument(column, measure);
    });

    const aggregationClassName = `${aggregationType || 'no'}-aggregation`;
    const filterBarProps = {
      addFilterDisabled: calculationNotConfigured || _.isEmpty(filterableColumns),
      className: `${aggregationClassName}-${filterTarget}`,
      filterTarget,
      filters
    };

    return (
      <FilterBar {...filterBarProps} />
    );
  }

  renderAccordionContainer() {
    const {
      aggregationType,
      calculationNotConfigured,
      displayableFilterableColumns,
      fixedDenominator,
      measure,
      onChangeFixedDenominator
    } = this.props;
    const fixedDenominatorId = 'fixed-denominator-input';
    const fixedDenominatorAttributes = {
      id: fixedDenominatorId,
      min: 0,
      className: 'text-input',
      onChange: (valueAsNumber, valueAsString) => onChangeFixedDenominator(valueAsString),
      value: fixedDenominator
    };

    const numeratorColumnDropdownOptions = {
      columnFieldName: this.props.numeratorColumnFieldName,
      calculationNotConfigured,
      displayableFilterableColumns,
      id: 'numerator-column',
      measure,
      measureArgument: NUMERATOR,
      onSelectColumn: this.props.onSelectNumeratorColumn
    };

    const denominatorColumnDropdownOptions = {
      columnFieldName: this.props.denominatorColumnFieldName,
      calculationNotConfigured,
      displayableFilterableColumns,
      id: 'denominator-column',
      measure,
      measureArgument: DENOMINATOR,
      onSelectColumn: this.props.onSelectDenominatorColumn
    };

    const i18nInputTitle = aggregationType === CalculationTypes.COUNT ?
      'count_direct_input_title' : 'direct_input_title';
    const numeratorAccordionPaneTitle = aggregationType === CalculationTypes.COUNT ?
      'count_numerator_title' : 'numerator_title';
    const denominatorAccordionPaneTitle = aggregationType === CalculationTypes.COUNT ?
      'count_denominator_title' : 'denominator_title';

    return (
      <AccordionContainer>
        <AccordionPane
          title={I18n.t(numeratorAccordionPaneTitle, { scope })}>
          <div className="option-subtitle">
            {I18n.t('numerator_subtitle', { scope })}
          </div>
          {
            aggregationType === CalculationTypes.SUM && <ColumnDropdown {...numeratorColumnDropdownOptions} />
          }
          {this.renderCalculationFilter(NUMERATOR)}
        </AccordionPane>
        <AccordionPane
          title={I18n.t(denominatorAccordionPaneTitle, { scope })}>
          <div className="option-subtitle">
            {I18n.t('denominator_subtitle', { scope })}
          </div>
          {
            aggregationType === CalculationTypes.SUM &&
              <ColumnDropdown {...denominatorColumnDropdownOptions} />
          }
          {this.renderCalculationFilter(DENOMINATOR)}
          <label htmlFor={fixedDenominatorId} className="rate-metric-denominator-direct-input">
            <h5>
              {I18n.t(i18nInputTitle, { scope })}
            </h5>
            <div className="option-subtitle">
              {I18n.t('direct_input_subtitle', { scope })}
            </div>
          </label>
          <NumericInput {...fixedDenominatorAttributes} />
        </AccordionPane>
      </AccordionContainer>
    );
  }

  renderAggregationDropdown() {
    const {
      aggregationType,
      onSelectAggregationType
    } = this.props;

    const aggregationDropdownOptions = {
      placeholder: I18n.t('shared.measures_editor.measure.edit_modal.calculation.choose_aggregation'),
      onSelection: (option) => {
        onSelectAggregationType(option.value);
      },
      options: [{
        title: I18n.t('shared.measures_editor.calculation_types.count'),
        value: CalculationTypes.COUNT
      }, {
        title: I18n.t('shared.measures_editor.calculation_types.sum'),
        value: CalculationTypes.SUM
      }],
      value: aggregationType,
      showOptionsBelowHandle: true,
      id: 'aggregation'
    };

    return (
      <Dropdown {...aggregationDropdownOptions} />
    );
  }

  renderConfigPane() {
    return (
      <div className="metric-config">
        <div className="option-title">
          {I18n.t('aggregation_title', { scope })}
        </div>
        <div className="option-subtitle">
          {I18n.t('aggregation_subtitle', { scope })}
        </div>
        {this.renderAggregationDropdown()}
        {this.renderAccordionContainer()}
      </div>
    );
  }

  renderDefinition() {
    const { aggregationType, measure } = this.props;
    const dividingByZero = _.get(this.props, 'computedMeasure.errors.dividingByZero');
    const formatOptions = getMeasureNumberFormat(measure);
    let numerator = _.get(this.props, 'computedMeasure.result.numerator');
    let denominator = _.get(this.props, 'computedMeasure.result.denominator');
    numerator = _.isEmpty(numerator) ? numerator :
      formatNumber(numerator.toNumber(), formatOptions);

    denominator = _.isEmpty(denominator) ? denominator :
      formatNumber(denominator.toNumber(), formatOptions);

    const callToActionClass = classNames(
      'rate-metric-call-to-action',
      { visible: !numerator && !denominator }
    );

    const callToActionText = this.props.aggregationType ?
      I18n.t('fraction_no_values', { scope }) :
      I18n.t('fraction_not_available', { scope });

    const denominatorClassName = classNames(
      'rate-metric-denominator',
      {
        'rate-metric-dividing-by-zero': dividingByZero
      }
    );
    const denominatorTitle = dividingByZero ?
      I18n.t('shared.performance_measures.measure.dividing_by_zero') :
      null;

    return (
      <div className="metric-definition-text">
        <div className="rate-metric-fraction">
          <h5>{I18n.t('sample_fraction', { scope })}</h5>
          <div className="rate-metric-numerator">
            {numerator}
          </div>
          <div className={denominatorClassName} title={denominatorTitle}>
            {denominator}
          </div>
          <div className={callToActionClass}>
            {callToActionText}
          </div>
        </div>
        <h5>
          {I18n.t('help_title', { scope })}
        </h5>
        <p>{I18n.t('help_body', { scope })}</p>
        <p>
          {aggregationType === CalculationTypes.COUNT && I18n.t('exclude_null_help_body', { scope })}
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="metric-container">
        {this.renderConfigPane()}
        {this.renderDefinition()}
      </div>
    );
  }
}

Rate.defaultProps = {
  calculationNotConfigured: false
};

Rate.propTypes = {
  aggregationType: PropTypes.string,
  calculationNotConfigured: PropTypes.bool,
  denominatorColumnFieldName: PropTypes.string,
  denominatorFilters: PropTypes.arrayOf(FilterPropType),
  displayableFilterableColumns: PropTypes.arrayOf(PropTypes.shape({
    renderTypeName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
  })),
  fixedDenominator: PropTypes.string,
  measure: measurePropType.isRequired,
  onSelectAggregationType: PropTypes.func.isRequired,
  onSelectDenominatorColumn: PropTypes.func.isRequired,
  onSelectNumeratorColumn: PropTypes.func.isRequired,
  onChangeFixedDenominator: PropTypes.func.isRequired,
  numeratorColumn: PropTypes.object,
  numeratorColumnFieldName: PropTypes.string,
  numeratorFilters: PropTypes.arrayOf(FilterPropType)
};

export function mapStateToProps(state) {
  const measure = _.get(state, 'editor.measure');
  const aggregationType = _.get(state, 'editor.measure.metricConfig.arguments.aggregationType');
  const denominatorColumnFieldName = _.get(state, 'editor.measure.metricConfig.arguments.denominatorColumn');
  const denominatorFilters = _.get(state, 'editor.measure.metricConfig.arguments.denominatorFilters', []);
  const displayableFilterableColumns = _.get(state, 'editor.displayableFilterableColumns');
  const fixedDenominator = _.get(state, 'editor.measure.metricConfig.arguments.fixedDenominator', '');
  let numeratorColumnFieldName = _.get(state, 'editor.measure.metricConfig.arguments.numeratorColumn');
  const numeratorColumn = _.find(displayableFilterableColumns, { fieldName: numeratorColumnFieldName });
  const numeratorFilters = _.get(state, 'editor.measure.metricConfig.arguments.numeratorFilters', []);

  if (numeratorColumnFieldName && !numeratorColumn) {
    // This can happen in two situations:
    // 1. We have a data source view loaded, but the numerator column is legitimately missing (i.e., it
    //    was hidden or deleted). This is the case if dataSourceView is not empty.
    // 2. The data source isn't loaded yet. We just have to wait a little for the state to update.
    //    We'll get called again later. This is the case if the data source view is empty.
    const dataSourceView = _.get(state, 'editor.dataSourceView');
    if (dataSourceView && dataSourceView !== LOADING_SENTINEL) {
      console.warn(`Numerator column not in filterable column set: ${numeratorColumnFieldName}, ignoring`);
    }
    // In either case, ignore the numerator column. If the data source loads, we'll get called again
    // and properly populate numeratorColumn. If the data source turns out not to have the column,
    // well, the user is given the chance to set a new column.
    numeratorColumnFieldName = undefined;
  }

  return {
    aggregationType,
    denominatorColumnFieldName,
    denominatorFilters,
    displayableFilterableColumns,
    fixedDenominator,
    numeratorColumn,
    numeratorColumnFieldName,
    numeratorFilters,
    measure
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSelectAggregationType: setAggregationType,
    onSelectDenominatorColumn: setDenominatorColumn,
    onSelectNumeratorColumn: setNumeratorColumn,
    onChangeFixedDenominator: setFixedDenominator
  }, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withComputedMeasure()
)(Rate);
